import { useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CheckCircleIcon } from '@heroicons/react/24/solid';
import localizationHelper from '@src/i18n';
import { PlainlyPackage, SubscriptionIdentifier } from '@src/models';

import { Button } from '../common';

type SubscriptionPackageProps = {
  name: string;
  price: number;
  plainlyPackage: PlainlyPackage;
  packageMinutes: number;
  packageStorage: number;
  packageRetention: number;
  packageHighPro: boolean;
  packageVideoGeniusVideos: number;
  concurrentRenders: number;
  onUpgrade?: () => void;
  selected?: boolean;
  yearly?: boolean;
};

type SubscriptionConcretePackageProps = Pick<SubscriptionPackageProps, 'selected' | 'yearly'> & {
  onUpgrade: (identifier: SubscriptionIdentifier) => void;
};

export const SubscriptionPackageStarterV1 = ({ onUpgrade, yearly, ...rest }: SubscriptionConcretePackageProps) => {
  const { t } = useTranslation();
  const onUpgradeFunc = useCallback(() => {
    if (yearly) {
      onUpgrade(SubscriptionIdentifier.STARTER_YEARLY);
    } else {
      onUpgrade(SubscriptionIdentifier.STARTER);
    }
  }, [onUpgrade, yearly]);

  return (
    <SubscriptionPackage
      name={t('components.user.common.starter')}
      price={!yearly ? 59 : 496}
      plainlyPackage={PlainlyPackage.STARTER}
      packageMinutes={25}
      packageStorage={1}
      packageRetention={24}
      packageHighPro={false}
      packageVideoGeniusVideos={5}
      concurrentRenders={4}
      yearly={yearly}
      onUpgrade={onUpgradeFunc}
      {...rest}
    />
  );
};

export const SubscriptionPackageTeamV1 = ({ onUpgrade, yearly, ...rest }: SubscriptionConcretePackageProps) => {
  const { t } = useTranslation();
  const onUpgradeFunc = useCallback(() => {
    if (yearly) {
      onUpgrade(SubscriptionIdentifier.TEAM_YEARLY);
    } else {
      onUpgrade(SubscriptionIdentifier.TEAM);
    }
  }, [onUpgrade, yearly]);

  return (
    <SubscriptionPackage
      name={t('components.user.common.team')}
      price={!yearly ? 249 : 2090}
      plainlyPackage={PlainlyPackage.TEAM}
      packageMinutes={125}
      packageStorage={5}
      packageRetention={48}
      packageHighPro={true}
      packageVideoGeniusVideos={5}
      concurrentRenders={8}
      yearly={yearly}
      onUpgrade={onUpgradeFunc}
      {...rest}
    />
  );
};

export const SubscriptionPackageProV1 = ({ onUpgrade, yearly, ...rest }: SubscriptionConcretePackageProps) => {
  const { t } = useTranslation();
  const onUpgradeFunc = useCallback(() => {
    if (yearly) {
      onUpgrade(SubscriptionIdentifier.PRO_YEARLY);
    } else {
      onUpgrade(SubscriptionIdentifier.PRO);
    }
  }, [onUpgrade, yearly]);

  return (
    <SubscriptionPackage
      name={t('components.user.common.pro')}
      price={!yearly ? 599 : 5030}
      plainlyPackage={PlainlyPackage.PRO}
      packageMinutes={325}
      packageStorage={15}
      packageRetention={72}
      packageHighPro={true}
      packageVideoGeniusVideos={5}
      concurrentRenders={16}
      yearly={yearly}
      onUpgrade={onUpgradeFunc}
      {...rest}
    />
  );
};

export const SubscriptionPackageStarterV2 = ({ onUpgrade, yearly, ...rest }: SubscriptionConcretePackageProps) => {
  const { t } = useTranslation();
  const onUpgradeFunc = useCallback(() => {
    if (yearly) {
      onUpgrade(SubscriptionIdentifier.STARTER_V2_YEARLY);
    } else {
      onUpgrade(SubscriptionIdentifier.STARTER_V2);
    }
  }, [onUpgrade, yearly]);

  return (
    <SubscriptionPackage
      name={t('components.user.common.starter_v2')}
      price={!yearly ? 69 : 579}
      plainlyPackage={PlainlyPackage.STARTER}
      packageMinutes={50}
      packageStorage={1}
      packageRetention={24}
      packageHighPro={false}
      packageVideoGeniusVideos={5}
      concurrentRenders={2}
      yearly={yearly}
      onUpgrade={onUpgradeFunc}
      {...rest}
    />
  );
};

export const SubscriptionPackageExplorerV2 = ({ onUpgrade, yearly, ...rest }: SubscriptionConcretePackageProps) => {
  const { t } = useTranslation();
  const onUpgradeFunc = useCallback(() => {
    if (yearly) {
      onUpgrade(SubscriptionIdentifier.EXPLORER_V2_YEARLY);
    } else {
      onUpgrade(SubscriptionIdentifier.EXPLORER_V2);
    }
  }, [onUpgrade, yearly]);

  return (
    <SubscriptionPackage
      name={t('components.user.common.explorer_v2')}
      price={!yearly ? 134 : 1129}
      plainlyPackage={PlainlyPackage.EXPLORER}
      packageMinutes={100}
      packageStorage={2}
      packageRetention={48}
      packageHighPro={false}
      packageVideoGeniusVideos={5}
      concurrentRenders={4}
      yearly={yearly}
      onUpgrade={onUpgradeFunc}
      {...rest}
    />
  );
};

export const SubscriptionPackageTeamV2 = ({ onUpgrade, yearly, ...rest }: SubscriptionConcretePackageProps) => {
  const { t } = useTranslation();
  const onUpgradeFunc = useCallback(() => {
    if (yearly) {
      onUpgrade(SubscriptionIdentifier.TEAM_V2_YEARLY);
    } else {
      onUpgrade(SubscriptionIdentifier.TEAM_V2);
    }
  }, [onUpgrade, yearly]);

  return (
    <SubscriptionPackage
      name={t('components.user.common.team_v2')}
      price={!yearly ? 259 : 2179}
      plainlyPackage={PlainlyPackage.TEAM}
      packageMinutes={200}
      packageStorage={5}
      packageRetention={72}
      packageHighPro={true}
      packageVideoGeniusVideos={5}
      concurrentRenders={8}
      yearly={yearly}
      onUpgrade={onUpgradeFunc}
      {...rest}
    />
  );
};

export const SubscriptionPackageProV2 = ({ onUpgrade, yearly, ...rest }: SubscriptionConcretePackageProps) => {
  const { t } = useTranslation();
  const onUpgradeFunc = useCallback(() => {
    if (yearly) {
      onUpgrade(SubscriptionIdentifier.PRO_V2_YEARLY);
    } else {
      onUpgrade(SubscriptionIdentifier.PRO_V2);
    }
  }, [onUpgrade, yearly]);

  return (
    <SubscriptionPackage
      name={t('components.user.common.pro_v2')}
      price={!yearly ? 649 : 5459}
      plainlyPackage={PlainlyPackage.PRO}
      packageMinutes={600}
      packageStorage={15}
      packageRetention={168}
      packageHighPro={true}
      packageVideoGeniusVideos={5}
      concurrentRenders={16}
      yearly={yearly}
      onUpgrade={onUpgradeFunc}
      {...rest}
    />
  );
};

const SubscriptionPackage = ({
  name,
  price,
  plainlyPackage,
  packageMinutes,
  packageStorage,
  packageRetention,
  packageHighPro,
  packageVideoGeniusVideos,
  concurrentRenders,
  onUpgrade,
  selected,
  yearly
}: SubscriptionPackageProps) => {
  const { t } = useTranslation();

  const limits = [
    t('components.user.SubscriptionPackage.packageVideoMins', { minutes: packageMinutes }),
    t('components.user.SubscriptionPackage.packageStorage', { storage: packageStorage }),
    t('components.user.SubscriptionPackage.packageConcurrentRenders', { concurrentRenders }),
    t('components.user.SubscriptionPackage.packageRetention', { retention: packageRetention }),
    t('components.user.SubscriptionPackage.packageVideoGeniusVideos', { videos: packageVideoGeniusVideos })
  ];

  const starterFeatures = [
    t('components.user.SubscriptionPackage.packagePriorityHigh', { context: `${packageHighPro}` })
  ];
  const explorerFeatures = [...starterFeatures];
  const teamFeatures = [...starterFeatures, t('components.user.SubscriptionPackage.packageTeamMembers')];
  const proFeatures = [...teamFeatures];

  return (
    <>
      <div
        className={classNames(
          'rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between',
          selected && 'ring-2 ring-indigo-500 ring-offset-2'
        )}
      >
        <div className="w-full sm:flex sm:items-start">
          <div className="w-full">
            <div className="font-medium text-gray-900">
              {name}
              <span className="ml-2 inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
                {t('components.user.SubscriptionPackage.packagePrice', {
                  price: localizationHelper.forNumber().format(price),
                  context: yearly ? 'YEARLY' : 'MONTHLY'
                })}
              </span>
            </div>
            <dl className="mt-1 grid grid-cols-1 gap-x-2 gap-y-0 sm:grid-cols-2">
              <div className="sm:col-span-1">
                {limits.map((s, i) => (
                  <CheckItem key={i} s={s} />
                ))}
              </div>
              <div className="sm:col-span-1">
                {plainlyPackage === PlainlyPackage.STARTER &&
                  starterFeatures.map((s, i) => <CheckItem key={i} s={s} />)}
                {plainlyPackage === PlainlyPackage.EXPLORER &&
                  explorerFeatures.map((s, i) => <CheckItem key={i} s={s} />)}
                {plainlyPackage === PlainlyPackage.TEAM && teamFeatures.map((s, i) => <CheckItem key={i} s={s} />)}
                {plainlyPackage === PlainlyPackage.PRO && proFeatures.map((s, i) => <CheckItem key={i} s={s} />)}
              </div>
            </dl>
          </div>
        </div>
        <div className="mt-4 sm:ml-6 sm:mt-0 sm:shrink-0">
          <Button onClick={onUpgrade} disabled={selected} secondary={selected}>
            {t('general.action.upgrade')}
          </Button>
        </div>
      </div>
    </>
  );
};

const CheckItem = ({ s }: { s: string }) => (
  <div>
    <div className="mt-1 flex items-center text-sm text-gray-600">
      {<CheckCircleIcon className="mr-1.5 h-5 w-5 shrink-0 text-green-400" aria-hidden="true" />}
      <div>{s}</div>
    </div>
  </div>
);
