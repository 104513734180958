import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { Button, PlainlyCombobox } from '@src/components/common';
import { StockAssetsSearchParams, useGetStockAssets } from '@src/hooks';
import { StockAssetPreference, StockAssetSearchOut } from '@src/models';

import { AssetsGrid } from './AssetsGrid';

const DEFAULT_SEARCH_SIZE = 5;

export const SearchAssets = ({
  selectedAsset,
  setSelectedAsset
}: {
  selectedAsset?: StockAssetSearchOut;
  setSelectedAsset: (selectedAsset?: StockAssetSearchOut, close?: boolean) => void;
}) => {
  const { t } = useTranslation();
  const [input, setInput] = useState('');
  const [searchParams, setSearchParams] = useState<StockAssetsSearchParams>({
    query: input,
    preference: StockAssetPreference.MIXED,
    size: DEFAULT_SEARCH_SIZE
  });

  const { isLoading, data: stockAssets, isRefetching } = useGetStockAssets(searchParams);
  const canFireSearch = !!input.trim() && !isLoading && !isRefetching;

  return (
    <>
      <div className="flex w-full flex-col gap-3 p-6 sm:flex-row">
        <div className="relative w-full">
          <div className="pointer-events-none absolute inset-y-0 left-2 flex items-center text-gray-400 focus-within:text-gray-600">
            <MagnifyingGlassIcon className="h-5 w-5" />
          </div>
          <input
            autoFocus
            onKeyDown={e => {
              if (e.key === 'Enter' && canFireSearch) {
                setSearchParams({ ...searchParams, query: input, size: DEFAULT_SEARCH_SIZE });
              }
            }}
            id="search_field"
            name="query"
            type="text"
            value={input}
            onChange={e => setInput(e.target.value)}
            className="block w-full rounded-md border-gray-300 pl-8 text-sm text-gray-900 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:placeholder-gray-400 focus:ring-indigo-500"
            placeholder={t('components.article.ArticleVideo.sceneSettings.sceneForm.modal.searchAssets')}
          />
        </div>
        <div className="flex gap-3">
          <PlainlyCombobox
            id="preference"
            disabled={!canFireSearch}
            data={Object.values(StockAssetPreference).map(preference => ({
              name: preference,
              item: preference,
              label: t('components.publishers.common.type', { context: preference }),
              selected: preference === searchParams.preference
            }))}
            clearDisabled
            onSelectionChange={item => {
              if (item) setSearchParams({ ...searchParams, preference: item });
            }}
            className="w-full sm:w-36"
          />
          <Button
            disabled={!canFireSearch}
            loading={isLoading}
            type="button"
            onClick={() => setSearchParams({ ...searchParams, query: input, size: DEFAULT_SEARCH_SIZE })}
          >
            {t('general.action.search')}
          </Button>
        </div>
      </div>
      <AssetsGrid
        assets={stockAssets && stockAssets.outs}
        isLoading={isLoading}
        isRefetching={isRefetching}
        isLoadMoreDisabled={!canFireSearch || searchParams.query !== input || isRefetching}
        selectedAsset={selectedAsset}
        setSelectedAsset={setSelectedAsset}
        loadMore={() =>
          setSearchParams({
            ...searchParams,
            query: input,
            size: searchParams.size ? searchParams.size + DEFAULT_SEARCH_SIZE : DEFAULT_SEARCH_SIZE
          })
        }
      />
    </>
  );
};
