import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ButtonWithDropdown } from '@src/components';
import { useNotifications } from '@src/hooks';
import { copyToClipboard } from '@src/utils';

type ButtonCopyCurlProps = {
  curlWithApiKey: string;
  curlWithoutApiKey: string;
  alwaysVisible?: boolean;
};

export const ButtonCopyCurl = ({ curlWithApiKey, curlWithoutApiKey, alwaysVisible }: ButtonCopyCurlProps) => {
  const { t } = useTranslation();
  const { notifyInfo } = useNotifications();

  const copyCurl = (curl: string, withApiKey: boolean) => {
    copyToClipboard(curl);
    notifyInfo(t('components.common.copyRenderToClipboardNotification', { context: withApiKey.toString() }));
  };

  return (
    <div className={classNames(alwaysVisible ? 'inline-flex' : 'hidden md:inline-flex', 'rounded-md shadow-sm')}>
      <ButtonWithDropdown
        labeled={{
          label: t('components.designs.DesignRenderCommand.copyCurl'),
          action: () => copyCurl(curlWithoutApiKey, false)
        }}
        buttons={[
          {
            key: 'copy',
            action: () => copyCurl(curlWithoutApiKey, false),
            label: t('general.action.copy')
          },
          {
            key: 'copyWithApiKey',
            action: () => copyCurl(curlWithApiKey, true),
            label: t('components.common.copyWithApiKey')
          }
        ]}
      />
    </div>
  );
};
