import { useCallback } from 'react';

import { isEmpty } from '@src/utils';

import { DesignInputErrors } from './DesignInputErrors';

export type DesignNumberInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  onFieldUpdate: (value?: number) => void;
  errors?: string[];
};

export const DesignNumberInput = ({
  id,
  name,
  'aria-describedby': ariaDescribedBy,
  step,
  min,
  max,
  value,
  onFieldUpdate,
  errors
}: DesignNumberInputProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const value = parseFloat(e.target.value);
        if (isNaN(value)) {
          onFieldUpdate(undefined);
        } else {
          onFieldUpdate(value);
        }
      } catch (error) {
        console.error(error);
        onFieldUpdate(undefined);
      }
    },
    [onFieldUpdate]
  );

  return (
    <>
      <input
        id={id}
        name={name}
        aria-describedby={ariaDescribedBy}
        type="number"
        inputMode="decimal"
        step={step}
        min={min}
        max={max}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        onChange={handleChange}
        value={value}
      />
      {!isEmpty(errors) && <DesignInputErrors errors={errors} />}
    </>
  );
};
