import { useCallback } from 'react';

import { Button, StyledA } from '@src/components';
import { DesignParameter } from '@src/models';
import { DynamicScript } from '@src/types';
import { generateCSVExample, isEmpty } from '@src/utils';

export const ActionGenerateCSV = ({
  parameters,
  dynamicScripts,
  projectName,
  templateName,
  type,
  text
}: {
  parameters: DesignParameter[] | undefined;
  dynamicScripts: DynamicScript[] | undefined;
  projectName: string | undefined;
  templateName: string | undefined;
  type: 'button' | 'link';
  text: string;
}) => {
  const disabled = (isEmpty(parameters) && isEmpty(dynamicScripts)) || (!parameters && !dynamicScripts);

  const generate = useCallback(() => {
    generateCSVExample(parameters, dynamicScripts, projectName, templateName);
  }, [dynamicScripts, parameters, projectName, templateName]);

  return (
    <>
      {type === 'button' ? (
        <Button onClick={generate} disabled={disabled}>
          {text}
        </Button>
      ) : (
        <StyledA className="cursor-pointer" onClick={generate} disabled={disabled}>
          {text}
        </StyledA>
      )}
    </>
  );
};
