import { useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@src/components/common';
import { Template } from '@src/models';

import { RenderingOptionsModal } from './RenderingOptionsModal';

export const TemplateDefaultRenderingOptions = ({ projectId, template }: { projectId: string; template: Template }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const postEncodingType = template.defaultRenderOptions?.outputFormat?.postEncoding?.type;
  const encodingFormat =
    postEncodingType === 'custom'
      ? template.defaultRenderOptions?.outputFormat?.postEncoding?.encodingFormat
      : undefined;
  const encodingParamsLine =
    postEncodingType === 'custom'
      ? template.defaultRenderOptions?.outputFormat?.postEncoding?.encodingParamsLine
      : undefined;

  return (
    <>
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t('components.project.template.TemplateDetails.templateDefaultRenderOptions')}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {t('components.project.template.TemplateDetails.templateDefaultRenderOptionsDesc')}
              </p>
            </div>
            <div className="ml-4 mt-2 shrink-0">
              <Button onClick={() => setShowModal(true)}>{t('general.action.edit')}</Button>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 px-4 sm:px-6">
          <dl className="grid grid-cols-1 divide-y divide-dashed divide-gray-200 text-sm text-gray-500 sm:grid-cols-2">
            {!template.defaultRenderOptions && (
              <div className="col-span-full flex items-center justify-center py-5">
                <span className="text-sm text-gray-900">
                  {t('components.project.template.TemplateDetails.templateNoDefaultRenderOptions')}
                </span>
              </div>
            )}
            {template.defaultRenderOptions?.outputFormat && (
              <div className="grid grid-cols-1 gap-x-4 gap-y-2 py-4 sm:col-span-2 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <dt className="font-medium text-gray-700">{t('components.render.common.outputFormat')}</dt>
                </div>
                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.common.outputModule')}</dt>
                  <dd className="mt-1 text-gray-900">
                    {(template.defaultRenderOptions?.outputFormat?.outputModule &&
                      t('components.render.common.outputModule', {
                        context: template.defaultRenderOptions?.outputFormat?.outputModule
                      })) ||
                      '-'}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.common.settingsTemplate')}</dt>
                  <dd className="mt-1 text-gray-900">
                    {(template.defaultRenderOptions?.outputFormat?.settingsTemplate &&
                      t('components.render.common.settingsTemplate', {
                        context: template.defaultRenderOptions?.outputFormat?.settingsTemplate
                      })) ||
                      '-'}
                  </dd>
                </div>
                {postEncodingType && (
                  <>
                    <div className={classNames(postEncodingType === 'custom' ? 'sm:col-span-1' : 'sm:col-span-2')}>
                      <dt className="font-medium">{t('components.render.common.postEncodingType')}</dt>
                      <dd className="mt-1 text-gray-900">
                        {t('components.render.common.postEncodingType', {
                          context: postEncodingType
                        })}
                      </dd>
                    </div>
                    {postEncodingType === 'custom' && (
                      <div className="sm:col-span-1">
                        <dt className="font-medium">{t('components.render.common.customPostEncoding')}</dt>

                        {encodingFormat && encodingParamsLine && (
                          <dd className="mt-1 text-gray-900">
                            <Trans
                              i18nKey={'components.render.common.customPostEncoding'}
                              context="both"
                              tOptions={{
                                encodingFormat: t('components.render.RenderForm.outputFormat', {
                                  context: encodingFormat
                                }),
                                encodingParamsLine
                              }}
                            >
                              {encodingFormat} with encoding params
                              <span className="prose prose-sm">
                                <code>{encodingParamsLine}</code>
                              </span>
                            </Trans>
                          </dd>
                        )}

                        {encodingFormat && !encodingParamsLine && (
                          <dd className="mt-1 text-gray-900">
                            {t('components.render.RenderForm.outputFormat', { context: encodingFormat })}
                          </dd>
                        )}

                        {!encodingFormat && encodingParamsLine && (
                          <dd className="mt-1 text-gray-900">
                            <Trans
                              i18nKey={'components.render.common.customPostEncoding'}
                              context="params"
                              tOptions={{ encodingFormat, encodingParamsLine }}
                            >
                              Encoding params
                              <span className="prose prose-sm ml-1">
                                <code>{encodingParamsLine}</code>
                              </span>
                            </Trans>
                          </dd>
                        )}
                      </div>
                    )}
                  </>
                )}

                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.RenderAdvancedOptionsForm.attachment')}</dt>
                  <dd className="mt-1 text-gray-900">
                    {template.defaultRenderOptions?.outputFormat?.attachment
                      ? t('general.common.yes')
                      : t('general.common.no')}
                  </dd>
                </div>
                {template.defaultRenderOptions.outputFormat.attachment && (
                  <div className="sm:col-span-1">
                    <dt className="font-medium">
                      {t('components.render.RenderAdvancedOptionsForm.attachmentFileName')}
                    </dt>
                    <dd className="mt-1 text-gray-900">
                      {template.defaultRenderOptions?.outputFormat?.attachmentFileName || '-'}
                    </dd>
                  </div>
                )}
              </div>
            )}
            {template.defaultRenderOptions?.webhook && (
              <div className="grid grid-cols-1 gap-x-4 gap-y-2 py-4 sm:col-span-2 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <dt className="font-medium text-gray-700">{t('components.render.common.webhook')}</dt>
                </div>
                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.common.webhookUrl')}</dt>
                  <dd className="mt-1 text-gray-900">{template.defaultRenderOptions?.webhook?.url || '-'}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.common.passthrough')}</dt>
                  <dd className="mt-1 text-gray-900">{template.defaultRenderOptions?.webhook?.passthrough || '-'}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.RenderAdvancedOptionsForm.webhookOnFailure')}</dt>
                  <dd className="mt-1 text-gray-900">
                    {template.defaultRenderOptions?.webhook?.onFailure
                      ? t('general.common.yes')
                      : t('general.common.no')}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.RenderAdvancedOptionsForm.webhookOnInvalid')}</dt>
                  <dd className="mt-1 text-gray-900">
                    {template.defaultRenderOptions?.webhook?.onInvalid
                      ? t('general.common.yes')
                      : t('general.common.no')}
                  </dd>
                </div>
              </div>
            )}
            {template.defaultRenderOptions?.options?.integrations && (
              <div className="grid grid-cols-1 gap-x-4 gap-y-2 py-4 sm:col-span-2 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <dt className="font-medium text-gray-700">{t('general.common.integrations')}</dt>
                </div>
                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.common.integrationsSkipAll')}</dt>
                  <dd className="mt-1 text-gray-900">
                    {template.defaultRenderOptions?.options?.integrations?.skipAll
                      ? t('general.common.yes')
                      : t('general.common.no')}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.common.passthrough')}</dt>
                  <dd className="mt-1 text-gray-900">
                    {template.defaultRenderOptions?.options?.integrations?.passthrough || '-'}
                  </dd>
                </div>
              </div>
            )}
            {template.defaultRenderOptions?.options?.captions && (
              <div className="grid grid-cols-1 gap-x-4 gap-y-2 py-4 sm:col-span-2 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <dt className="font-medium text-gray-700">
                    {t('components.render.RenderAdvancedOptionsForm.captionsSection')}
                  </dt>
                </div>
                {template.defaultRenderOptions.options.captions.srtFileUrl && (
                  <div className="sm:col-span-2">
                    <dt className="font-medium">{t('components.render.RenderAdvancedOptionsForm.captionsSrtUrl')}</dt>
                    <dd className="mt text-gray-900">{template.defaultRenderOptions.options.captions.srtFileUrl}</dd>
                  </div>
                )}
                {template.defaultRenderOptions.options.captions.captionsStyle && (
                  <div className="sm:col-span-1">
                    <dt className="font-medium">{t('components.render.RenderAdvancedOptionsForm.captionsStyle')}</dt>
                    <dd className="mt-1 text-gray-900">
                      {t('components.render.RenderAdvancedOptionsForm.captionsStyle', {
                        context: template.defaultRenderOptions.options.captions.captionsStyle
                      })}
                    </dd>
                  </div>
                )}
                {template.defaultRenderOptions.options.captions.captionsPosition && (
                  <div className="sm:col-span-1">
                    <dt className="font-medium">{t('components.render.RenderAdvancedOptionsForm.captionsPosition')}</dt>
                    <dd className="mt-1 text-gray-900">
                      {t('components.render.RenderAdvancedOptionsForm.captionsPosition', {
                        context: template.defaultRenderOptions.options.captions.captionsPosition
                      })}
                    </dd>
                  </div>
                )}
              </div>
            )}
            {template.defaultRenderOptions?.options?.thumbnails && (
              <div className="grid grid-cols-1 gap-x-4 gap-y-2 py-4 sm:col-span-2 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <dt className="font-medium text-gray-700">{t('components.render.common.thumbnails')}</dt>
                </div>
                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.RenderAdvancedOptionsForm.atSeconds')}</dt>
                  <dd className="mt-1 text-gray-900">
                    {template.defaultRenderOptions.options.thumbnails.atSeconds?.join(', ') || '-'}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.RenderAdvancedOptionsForm.frequencySeconds')}</dt>
                  <dd className="mt-1 text-gray-900">
                    {template.defaultRenderOptions.options.thumbnails.frequencySeconds || '-'}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.RenderAdvancedOptionsForm.fromEncodedVideo')}</dt>
                  <dd className="mt-1 text-gray-900">
                    {template.defaultRenderOptions.options.thumbnails.fromEncodedVideo
                      ? t('general.common.yes')
                      : t('general.common.no')}
                  </dd>
                </div>
                {template.defaultRenderOptions.options.thumbnails.format && (
                  <div className="sm:col-span-1">
                    <dt className="font-medium">{t('components.render.RenderAdvancedOptionsForm.thumbnailsFormat')}</dt>
                    <dd className="mt-1 text-gray-900">
                      {t('components.render.RenderAdvancedOptionsForm.thumbnailFormat', {
                        context: template.defaultRenderOptions.options.thumbnails.format
                      })}
                    </dd>
                  </div>
                )}
              </div>
            )}
            {template.defaultRenderOptions?.options?.watermark && (
              <div className="grid grid-cols-1 gap-x-4 gap-y-2 py-4 sm:col-span-2 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <dt className="font-medium text-gray-700">{t('components.render.common.watermark')}</dt>
                </div>
                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.common.watermarkUrl')}</dt>
                  <dd className="mt-1 text-gray-900">{template.defaultRenderOptions.options.watermark.url}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.common.encodingParams')}</dt>
                  <dd className="mt-1 text-gray-900">
                    {template.defaultRenderOptions.options.watermark.encodingParamsLine || '-'}
                  </dd>
                </div>
              </div>
            )}
            {template.defaultRenderOptions?.options?.projectFiles?.uploadEnabled && (
              <div className="grid grid-cols-1 gap-x-4 gap-y-2 py-4 sm:col-span-2 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <dt className="font-medium text-gray-700">{t('components.common.project')}</dt>
                </div>
                <div className="sm:col-span-1">
                  <dt className="font-medium">{t('components.render.RenderAdvancedOptionsForm.projectFilesUpload')}</dt>
                  <dd className="mt-1 text-gray-900">{t('general.common.yes')}</dd>
                </div>
              </div>
            )}
          </dl>
        </div>
      </div>
      <RenderingOptionsModal
        visible={showModal}
        setShowModal={setShowModal}
        template={template}
        projectId={projectId}
      />
    </>
  );
};
