import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PlainlyCombobox } from '@src/components';
import { useGetDetailedResourceUsage, useQueryParams } from '@src/hooks';
import localizationHelper from '@src/i18n';

export type UsageComboboxProps = {
  notSelectedLabel?: string;
  resourceType: string | undefined;
};

export const UsageCombobox = ({ notSelectedLabel, resourceType }: UsageComboboxProps) => {
  const { t } = useTranslation();
  const { searchQuery, updateQueryParams } = useQueryParams();
  const navigate = useNavigate();

  const startDate = searchQuery.get('bp') || undefined;

  const { data } = useGetDetailedResourceUsage({
    resourceType: resourceType
  });
  const billingPeriods = data?.previousBillingPeriods;

  return (
    <PlainlyCombobox
      id="billing-period"
      className="w-full md:w-80"
      data={billingPeriods?.map(bp => ({
        label: t('components.usage.DailyUsageCombobox.billingPeriod', {
          start: localizationHelper.forDate().formatDateStringLocally(bp.startDate),
          end: localizationHelper.forDate().formatDateStringLocally(bp.endDate)
        }),
        item: bp,
        selected: bp.startDate === startDate
      }))}
      notSelectedLabel={notSelectedLabel}
      onSelectionChange={billingPeriod => {
        navigate({
          search: `?${updateQueryParams(window.location, {
            bp: billingPeriod?.startDate
          })}`
        });
      }}
    />
  );
};
