import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionGenerateCSV } from '@src/components/common';
import { getDynamicScripts } from '@src/components/render';
import { DesignParameter, Template } from '@src/models';
import { layerToParameter } from '@src/utils';

export const TemplateGenerateCsv = ({ template, projectName }: { template: Template; projectName?: string }) => {
  const { t } = useTranslation();

  const parameters = useMemo(
    () =>
      template?.layers
        .filter((l, index, array) => l.parametrization?.expression === true && array.indexOf(l) === index)
        .flatMap(l => layerToParameter(l) as DesignParameter),
    [template?.layers]
  );

  const dynamicScripts = useMemo(() => getDynamicScripts(template), [template]);

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t('components.project.template.TemplateDetails.generateCSVTitle')}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {t('components.project.template.TemplateDetails.generateCSVDescription')}
            </p>
          </div>
          <div className="ml-4 mt-2 shrink-0">
            <ActionGenerateCSV
              parameters={parameters}
              dynamicScripts={dynamicScripts}
              projectName={projectName}
              templateName={template.name}
              type="button"
              text={t('general.action.generate')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
