import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CalendarIcon,
  CircleStackIcon,
  NewspaperIcon,
  ShieldCheckIcon,
  VideoCameraIcon
} from '@heroicons/react/20/solid';
import { ClipboardDocumentCheckIcon, EyeIcon, EyeSlashIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useNotifications } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { ApiKeyDto, ResourceType } from '@src/models';
import { copyToClipboard, formatResourceUsage, isValidNumber, toMb } from '@src/utils';

import { StyledA } from '../common';

export const ApiKeyItem = ({
  apiKey,
  edit,
  deleteKey
}: {
  apiKey: ApiKeyDto;
  edit: () => void;
  deleteKey: () => void;
}) => {
  const { t } = useTranslation();
  const { notifyInfo } = useNotifications();
  const [shown, setShown] = useState(false);

  const toggleApiKeyShown = useCallback(() => setShown(prev => !prev), []);

  const copyApiKey = useCallback(() => {
    copyToClipboard(apiKey.key);
    notifyInfo(t('components.user.UserOrganizationCard.copyToClipboardNotification'));
  }, [apiKey.key, notifyInfo, t]);

  return (
    <>
      {/* mobile screen */}
      <li className="flex flex-col gap-1 p-3 lg:hidden">
        <div className="flex items-center justify-between">
          <p>{apiKey.nickname ? apiKey.nickname : t('components.user.UserOrganizationCard.default')}</p>
          <Actions
            shown={shown}
            toggleApiKeyShown={toggleApiKeyShown}
            copyToClipboard={copyApiKey}
            edit={edit}
            deleteKey={deleteKey}
          />
        </div>
        <Key apiKey={apiKey} shown={shown} />
        <ApiKeyLimits limits={apiKey.limits} />
        <Expire apiKey={apiKey} />
      </li>

      {/* large screen */}
      <li className="hidden w-full flex-col gap-1 p-3 lg:flex xl:hidden">
        <div className="flex w-full items-center">
          <p className="truncate">
            {apiKey.nickname ? apiKey.nickname : t('components.user.UserOrganizationCard.default')}
          </p>
          <Actions
            shown={shown}
            toggleApiKeyShown={toggleApiKeyShown}
            copyToClipboard={copyApiKey}
            edit={edit}
            deleteKey={deleteKey}
          />
        </div>
        <div className="flex flex-col gap-1">
          <Key apiKey={apiKey} shown={shown} />
          <ApiKeyLimits limits={apiKey.limits} />
          <Expire apiKey={apiKey} />
        </div>
      </li>

      {/* extra large screen */}
      <li className="hidden w-full flex-col gap-1 p-3 xl:flex">
        <div className="flex w-full items-center">
          <p className="w-1/6 truncate">
            {apiKey.nickname ? apiKey.nickname : t('components.user.UserOrganizationCard.default')}
          </p>
          <Key apiKey={apiKey} shown={shown} />
          <ApiKeyLimits limits={apiKey.limits} />
          <Actions
            shown={shown}
            toggleApiKeyShown={toggleApiKeyShown}
            copyToClipboard={copyApiKey}
            edit={edit}
            deleteKey={deleteKey}
          />
        </div>
        <Expire apiKey={apiKey} />
      </li>
    </>
  );
};

const Actions = ({
  shown,
  toggleApiKeyShown,
  copyToClipboard,
  edit,
  deleteKey
}: {
  shown: boolean;
  toggleApiKeyShown: () => void;
  copyToClipboard: () => void;
  edit: () => void;
  deleteKey: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center gap-2 md:hidden">
        {shown && <EyeIcon onClick={toggleApiKeyShown} className="h-5 w-5 shrink-0 text-indigo-600" />}
        {!shown && <EyeSlashIcon onClick={toggleApiKeyShown} className="h-5 w-5 shrink-0 text-indigo-600" />}
        <ClipboardDocumentCheckIcon onClick={copyToClipboard} className="h-5 w-5 shrink-0 text-indigo-600" />
        <PencilIcon onClick={edit} className="h-5 w-5 shrink-0 text-indigo-600" />
        <TrashIcon onClick={deleteKey} className="h-5 w-5 shrink-0 text-red-600" />
      </div>
      <div className="ml-auto hidden items-center gap-2 md:flex md:gap-4">
        <div className="shrink-0">
          <StyledA onClick={toggleApiKeyShown} href="#">
            {t('components.user.UserOrganizationCard.apiKeyShown', { context: `${shown}` })}
          </StyledA>
        </div>
        <div className="shrink-0">
          <StyledA onClick={copyToClipboard} className="truncate" href="#">
            {t('general.action.copy')}
          </StyledA>
        </div>
        <div className="shrink-0">
          <StyledA onClick={edit} href="#">
            {t('general.action.edit')}
          </StyledA>
        </div>
        <div className="shrink-0">
          <StyledA
            onClick={deleteKey}
            className="truncate text-red-600 focus-within:ring-red-500 hover:text-red-500"
            href="#"
          >
            {t('general.action.delete')}
          </StyledA>
        </div>
      </div>
    </>
  );
};

const Key = ({ apiKey, shown }: { apiKey: ApiKeyDto; shown: boolean }) => (
  <div className="flex items-center gap-1 xl:w-80">
    <ShieldCheckIcon className="h-5 w-5 shrink-0 text-gray-400" />
    <span className="truncate" id="apiKey">
      {shown ? apiKey.key : '**************************'}
    </span>
  </div>
);

const Expire = ({ apiKey }: { apiKey: ApiKeyDto }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-1 whitespace-nowrap">
      <CalendarIcon
        className="h-5 w-5 shrink-0 text-gray-400"
        title={
          apiKey.expiryDate &&
          t('components.user.UserOrganizationCard.apiKeyExpires', {
            date: localizationHelper.forDate().formatDateTimeStringLocally(apiKey.expiryDate)
          })
        }
      />
      <span>
        {apiKey.expired
          ? t('components.common.expired')
          : (apiKey.expiryDate &&
              t('components.user.UserOrganizationCard.apiKeyExpires', {
                date: localizationHelper.forDate().formatDateStringLocally(apiKey.expiryDate)
              })) ||
            t('components.user.UserOrganizationCard.apiKeyNeverExpires')}
      </span>
    </div>
  );
};

const ApiKeyLimits = ({ limits }: { limits?: Partial<Record<ResourceType, number>> }) => {
  const { t } = useTranslation();

  return (
    <>
      {limits && (
        <div className="flex items-center gap-3 xl:mr-2">
          {isValidNumber(limits.STORAGE) && (
            <div className="flex items-center gap-1">
              <CircleStackIcon className="h-5 w-5 shrink-0 text-gray-400" />
              <span className="whitespace-nowrap">
                {t('components.user.UserOrganizationCard.resourceUsage', {
                  context: 'STORAGE',
                  count: Math.round(limits.STORAGE / 1024 / 1024),
                  megabytes: formatResourceUsage(toMb(limits.STORAGE), 0)
                })}
              </span>
            </div>
          )}
          {isValidNumber(limits.RENDERING_VIDEO_DURATION) && (
            <div className="flex items-center gap-1">
              <VideoCameraIcon className="h-5 w-5 shrink-0 text-gray-400" />
              <span className="whitespace-nowrap">
                {t('components.user.UserOrganizationCard.resourceUsage', {
                  context: 'RENDERING_VIDEO_DURATION',
                  count: Math.round(limits.RENDERING_VIDEO_DURATION / 60),
                  decimal: formatResourceUsage(limits.RENDERING_VIDEO_DURATION / 60, 0),
                  minutes: formatResourceUsage(limits.RENDERING_VIDEO_DURATION / 60, 0)
                })}
              </span>
            </div>
          )}
          {isValidNumber(limits.ARTICLE_VIDEOS) && (
            <div className="flex items-center gap-1">
              <NewspaperIcon className="h-5 w-5 shrink-0 text-gray-400" />
              <span className="whitespace-nowrap">
                {t('components.user.UserOrganizationCard.resourceUsage', {
                  context: 'ARTICLE_VIDEOS',
                  count: limits.ARTICLE_VIDEOS
                })}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};
