import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Checkbox, ExcludeLayers, TargetComposition } from '@src/components';
import { AnyAutoCreateTemplateDto, AutoCreateTemplateType } from '@src/models';

export const PrefixAutoGenerateTemplatesForm = ({
  setData,
  projectId
}: {
  setData: (data: AnyAutoCreateTemplateDto | undefined) => void;
  projectId: string;
}) => {
  const { t } = useTranslation();
  const [prefixes, setPrefixes] = useState<string[]>();
  const [stripPrefix, setStripPrefix] = useState(false);
  const [targetCompositionName, setTargetCompositionName] = useState<string | undefined>();
  const [exclude, setExclude] = useState({
    excludeAdjustmentLayers: false,
    excludeDisabledLayers: false,
    excludeGuideLayers: false,
    excludeShyLayers: false,
    applyExclusionsToCompositions: true
  });

  useEffect(() => {
    setData({
      type: AutoCreateTemplateType.prefix,
      prefixes: prefixes?.map(prefix => prefix.trim()),
      stripPrefix,
      targetCompositionName: targetCompositionName || undefined,
      excludeAdjustmentLayers: exclude.excludeAdjustmentLayers,
      excludeDisabledLayers: exclude.excludeDisabledLayers,
      excludeGuideLayers: exclude.excludeGuideLayers,
      excludeShyLayers: exclude.excludeShyLayers,
      applyExclusionsToCompositions: exclude.applyExclusionsToCompositions
    });
  }, [
    prefixes,
    stripPrefix,
    setData,
    targetCompositionName,
    exclude.excludeAdjustmentLayers,
    exclude.excludeDisabledLayers,
    exclude.excludeGuideLayers,
    exclude.excludeShyLayers,
    exclude.applyExclusionsToCompositions
  ]);

  return (
    <div className="max-h-vh-300 space-y-5 overflow-auto px-4 py-5">
      <fieldset className="space-y-5">
        <TargetComposition
          targetCompositionName={targetCompositionName}
          setTargetCompositionName={setTargetCompositionName}
          context="prefix"
          projectId={projectId}
        />
        <div>
          <label htmlFor="prefixes" className="block text-sm font-medium text-gray-700">
            {t('components.project.autoGenerateTemplates.PrefixAutoGenerateTemplatesForm.prefixes')}
            <p className="text-sm font-normal text-gray-500">
              <Trans
                i18nKey={'components.project.autoGenerateTemplates.PrefixAutoGenerateTemplatesForm.prefixesDesc'}
                tOptions={{ plainly: 'plainly' }}
              >
                Comma separated list of prefixes that will be used to filter compositions and layers for auto
                parametrization. If not provided, we will look for
                <span className="prose prose-sm">
                  <code>plainly</code>
                </span>
                prefix in your project.
              </Trans>
            </p>
          </label>
          <input
            autoFocus
            id="prefixes"
            name="prefixes"
            type="text"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            defaultValue={prefixes?.join(',')}
            onChange={e => setPrefixes(e.target.value.split(','))}
            placeholder={t('components.project.autoGenerateTemplates.PrefixAutoGenerateTemplatesForm.pPlaceholder')}
          />
        </div>
        <Checkbox
          id={'stripPrefix'}
          checked={stripPrefix === true}
          onChange={e => setStripPrefix(e.target.checked)}
          label={t('components.project.autoGenerateTemplates.PrefixAutoGenerateTemplatesForm.stripPrefix')}
          description={t('components.project.autoGenerateTemplates.PrefixAutoGenerateTemplatesForm.stripPrefixDesc')}
        />
        <ExcludeLayers exclude={exclude} setExclude={setExclude} />
      </fieldset>
    </div>
  );
};
