import { useTranslation } from 'react-i18next';

import { MediaInput } from '@src/components/common';

export const GeneralView = ({
  inputs,
  disabled,
  setGeneralSettings,
  handleInvalidUrls
}: {
  inputs: { cta?: string; logoUrl?: string };
  disabled?: boolean;
  setGeneralSettings: (newGeneralSettings: { cta?: string; logoUrl?: string }) => void;
  handleInvalidUrls: (key: string) => (url: string, valid: boolean, validating: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-6">
      <div>
        <label htmlFor="cta" className="block text-sm font-medium text-gray-700">
          {t('components.article.ArticleVideo.socialMediaSettings.form.cta')}
        </label>
        <p className="text-sm text-gray-500">
          {t('components.article.ArticleVideo.socialMediaSettings.form.ctaDescription')}
        </p>
        <input
          disabled={disabled}
          type="text"
          name="cta"
          id="cta"
          className="mt-1 block w-full rounded-md border-gray-300 text-xs shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm"
          onChange={e => setGeneralSettings({ ...inputs, cta: e.target.value })}
          value={inputs.cta}
        />
      </div>
      <div>
        <label htmlFor="logoUrl" className="block text-sm font-medium text-gray-700">
          {t('components.publishers.forms.logoUrl')}
        </label>
        <p className="text-sm text-gray-500">
          {t('components.article.ArticleVideo.socialMediaSettings.form.logoUrlDescription')}
        </p>
        <div className="mt-1">
          <MediaInput
            disabled={disabled}
            onFieldUpdate={value => setGeneralSettings({ ...inputs, logoUrl: value })}
            value={inputs.logoUrl}
            onValidation={handleInvalidUrls('logoUrl')}
          />
        </div>
      </div>
    </div>
  );
};
