import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { arrayMove } from '@dnd-kit/sortable';
import { AeItemType, AeMediaItemType, MediaAutoScaleTransform, ScriptType } from '@plainly/types';
import { Button, EditableAeItemWithCompositions, LayerParametrizeScriptingModal, WideSlideover } from '@src/components';
import {
  CompositionAeItem,
  CompositionIdentifier,
  Layer,
  LayerType,
  MediaAeItem,
  Parametrization,
  Script
} from '@src/models';
import { isEmpty } from '@src/utils';

import { LayerParametrizeForm } from './LayerParametrizeForm';
import { LayerParametrizeScripting } from './LayerParametrizeScripting';
import { LayerParametrizeTabs, LayerParametrizeTabsValue } from './LayerParametrizeTabs';

export type SaveValidation = {
  hasData?: boolean;
  isValid?: boolean;
};

type LayerParametrizeProps = {
  item?: EditableAeItemWithCompositions;
  editMode: boolean;
  onHide: () => void;
  onSave: (layer: Partial<Layer>) => void;
  onUpdate: (layer: Partial<Layer>) => void;
};

const allCompositionsIndex = '-1';

export const LayerParametrize = ({ item, editMode, onHide, onSave, onUpdate }: LayerParametrizeProps) => {
  const { t } = useTranslation();

  const [paramValidation, setParamValidation] = useState<SaveValidation>();
  const [scriptValidation, setScriptValidation] = useState<SaveValidation>();
  const [composition, setComposition] = useState<string>(allCompositionsIndex);
  const [effectPropertyName, setEffectPropertyName] = useState<string | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<string | undefined>(LayerParametrizeTabsValue.TEMPLATE_VALUE);
  const [identifier, setIdentifier] = useState<string | undefined>();
  const [parametrization, setParametrization] = useState<Parametrization | undefined>(undefined);
  const [showScriptingModal, setShowScriptingModal] = useState<boolean>(false);
  const [selectedScripts, setSelectedScripts] = useState<Script[]>([]);
  const [editScript, setEditScript] = useState<Script | undefined>();

  const canSaveParam = !paramValidation?.hasData || paramValidation.isValid;
  const canSaveScript = !scriptValidation?.hasData || scriptValidation.isValid;
  const canSave = canSaveParam && canSaveScript && (paramValidation?.hasData || scriptValidation?.hasData);
  const visible = item !== undefined;
  const layer = item?.layer;
  const compositionItem = layer?.type === AeItemType.COMPOSITION;
  const effects = layer?.effects || [];
  const isEffect = effectPropertyName !== undefined;

  // update identifier based on the layer
  useEffect(() => {
    setIdentifier(layer?.name);
    if (compositionItem || (!item?.plainlyLayer?.parametrization && item?.plainlyLayer?.scripting)) {
      setActiveTab(LayerParametrizeTabsValue.SCRIPTING);
    } else {
      setActiveTab(LayerParametrizeTabsValue.TEMPLATE_VALUE);
    }
  }, [layer, compositionItem, item?.plainlyLayer?.parametrization, item?.plainlyLayer?.scripting]);

  useEffect(() => {
    if (editMode) {
      setParametrization(item?.plainlyLayer?.parametrization);
      setSelectedScripts(item?.plainlyLayer?.scripting?.scripts || []);
      setIdentifier(item?.plainlyLayer?.label);

      if (item?.plainlyLayer?.layerType === LayerType.DATA_EFFECT) {
        setEffectPropertyName(item?.plainlyLayer?.propertyName);
      }

      // if we have single composition, select it
      const singleComposition = (item?.plainlyLayer?.compositions.length || 0) === 1;

      // then try to find the same in the offered ones
      if (singleComposition) {
        const composition = item?.plainlyLayer?.compositions[0];
        const containsConcatenation = composition?.name.includes('->');

        const index = item?.compositions?.findIndex(c => {
          if (!containsConcatenation) {
            // this is old style, just match index
            return c[c.length - 1].id === composition?.id;
          } else {
            // this is new style, match the whole string
            return composition?.name === c.map(cc => cc.name).join('->');
          }
        });

        if (index !== undefined && index >= 0) {
          setComposition(`${index}`);
        }
      }
    }
  }, [editMode, item]);

  const close = () => {
    // don't close if scripting modal is open
    if (!showScriptingModal) {
      // reset the form's state
      setIdentifier(undefined);
      setComposition(allCompositionsIndex);
      setSelectedScripts([]);
      setParametrization(undefined);
      setEffectPropertyName(undefined);
      // then fire props
      onHide();
    }
  };

  const save = () => {
    const l = getLayer();
    l && (editMode ? onUpdate(l) : onSave(l));
    close();
  };

  const getLayer = (): Partial<Layer> | undefined => {
    if (layer) {
      // resolve compositions
      const compositions = item?.compositions
        .filter((_, index) => composition === allCompositionsIndex || index === parseInt(composition))

        .map(compArray => {
          const i: CompositionIdentifier = {
            id: compArray[compArray.length - 1].id,
            name: compArray.map(comp => comp.name).join('->')
          };
          return i;
        });

      // basic stuff
      let result: Partial<Layer> = {
        internalId: layer.internalId,
        label: identifier || layer.name,
        layerName: layer.name,
        compositions: compositions || [],
        parametrization
      };

      // Effect parametrization takes precedence
      if (effectPropertyName) {
        return {
          ...result,
          layerType: LayerType.DATA_EFFECT,
          propertyName: effectPropertyName,
          effectType: effects.find(e => e.propertyName === effectPropertyName)?.type
        };
      } else {
        // Append scripts
        result = {
          ...result,
          scripting: !isEmpty(selectedScripts) ? { scripts: selectedScripts } : undefined
        };
      }

      switch (item?.layer.type) {
        case AeItemType.TEXT:
          return {
            ...result,
            layerType: LayerType.DATA,
            propertyName: 'Source Text'
          };

        case AeItemType.COMPOSITION:
          return {
            ...result,
            layerType: LayerType.COMPOSITION,
            layerName: (item.layer as CompositionAeItem).layerName || result.layerName
          };

        case AeItemType.MEDIA:
          switch ((item?.layer as MediaAeItem).mediaType) {
            case AeMediaItemType.IMAGE:
              return {
                ...result,
                layerType: LayerType.MEDIA,
                mediaType: 'image'
              };

            case AeMediaItemType.VIDEO:
              return {
                ...result,
                layerType: LayerType.MEDIA,
                mediaType: 'video'
              };

            case AeMediaItemType.AUDIO:
              return {
                ...result,
                layerType: LayerType.MEDIA,
                mediaType: 'audio'
              };

            case AeMediaItemType.SOLID:
              return {
                ...result,
                layerType: LayerType.SOLID_COLOR
              };
          }
      }
    }
  };

  const getComposition = () => {
    const index = parseInt(composition);
    if (index >= 0) {
      // take last composition from the selected array
      const compositionArray = item?.compositions && item?.compositions[index];
      return compositionArray ? [compositionArray[compositionArray.length - 1]] : ([] as CompositionIdentifier[]);
    } else {
      // take last composition from all arrays
      return item?.compositions.reduce((previous, next) => {
        previous.push(next[next.length - 1]);
        return previous;
      }, [] as CompositionIdentifier[]);
    }
  };

  const autoScaleMediaExists = useMemo(
    () => selectedScripts.some(s => s.scriptType === ScriptType.MEDIA_AUTO_SCALE),
    [selectedScripts]
  );

  const onAutoScaleChangeMedia = (
    autoScale: boolean,
    params: { fill: boolean; fixedRatio: boolean; transform: MediaAutoScaleTransform | undefined }
  ) => {
    if (autoScale && !autoScaleMediaExists) {
      setSelectedScripts(prev => [
        ...prev,
        {
          scriptType: ScriptType.MEDIA_AUTO_SCALE,
          fill: params.fill || true,
          fixedRatio: params.fixedRatio || true,
          transform: params.transform || undefined
        }
      ]);
    } else if (!autoScale && autoScaleMediaExists) {
      setSelectedScripts(prev => prev.filter(s => s.scriptType !== ScriptType.MEDIA_AUTO_SCALE));
    }
  };

  const autoScaleTextExists = useMemo(
    () => selectedScripts.some(s => s.scriptType === ScriptType.TEXT_AUTO_SCALE),
    [selectedScripts]
  );

  const onAutoScaleChangeText = (autoScale: boolean) => {
    if (autoScale && !autoScaleTextExists) {
      setSelectedScripts(prev => [
        ...prev,
        {
          scriptType: ScriptType.TEXT_AUTO_SCALE
        }
      ]);
    } else if (!autoScale && autoScaleTextExists) {
      setSelectedScripts(prev => prev.filter(s => s.scriptType !== ScriptType.TEXT_AUTO_SCALE));
    }
  };

  const getEffectValue = () => {
    if (layer && effectPropertyName) {
      const effect = effects.find(e => e.propertyName === effectPropertyName);

      if (Array.isArray(effect?.value)) {
        return effect?.value.join(', ') || '-';
      }

      return effect ? effect.value : '-';
    }
  };

  const closeModal = useCallback(() => {
    setShowScriptingModal(false);
    setEditScript(undefined);
  }, []);

  const onDragAndDrop = useCallback(
    (oldIndex: number, newIndex: number) => setSelectedScripts(arrayMove(selectedScripts, oldIndex, newIndex)),
    [selectedScripts]
  );

  return (
    <>
      <WideSlideover
        visible={visible}
        onClose={close}
        title={t('components.project.template.layer.LayerParametrize.title')}
        subtitle={t('components.project.template.layer.LayerParametrize.subtitle')}
      >
        <form
          className="flex h-full flex-col divide-y divide-gray-200 overflow-y-auto"
          onSubmit={e => {
            e.preventDefault();
            save();
          }}
        >
          <div className="h-0 flex-1 overflow-y-auto">
            <div className="flex flex-1 flex-col justify-between">
              <div className="divide-y divide-gray-200 px-4 sm:px-6">
                <div className="space-y-6 pb-5 pt-6">
                  <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    {compositionItem ? (
                      <>
                        {layer.layerName && (
                          <div className="sm:col-span-1">
                            <h3 className="block text-sm font-medium text-gray-900">
                              {t('components.project.template.layer.LayerParametrize.layerName')}
                            </h3>
                            <p className="mt-1 truncate text-sm text-gray-500">{layer.layerName}</p>
                          </div>
                        )}
                        <div className="sm:col-span-1">
                          <h3 className="block text-sm font-medium text-gray-900">
                            {t('components.project.template.layer.LayerParametrize.compositionName')}
                          </h3>
                          <p className="mt-1 truncate text-sm text-gray-500">{layer.name}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="sm:col-span-1">
                          <h3 className="block text-sm font-medium text-gray-900">
                            {t('components.project.template.layer.LayerParametrize.layerName')}
                          </h3>
                          <p className="mt-1 truncate text-sm text-gray-500">{layer?.name}</p>
                        </div>
                        <div className="sm:col-span-1">
                          <h3 className="block text-sm font-medium text-gray-900">
                            {isEffect
                              ? t('components.project.template.layer.LayerParametrize.currentEffectValue')
                              : t('components.project.template.layer.LayerParametrize.currentValue')}
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">{isEffect ? getEffectValue() : layer?.value}</p>
                        </div>
                      </>
                    )}
                    {layer?.type === AeItemType.TEXT && (
                      <>
                        {layer?.font && (
                          <div className="sm:col-span-1">
                            <h3 className="block text-sm font-medium text-gray-900">
                              {t('components.project.template.layer.LayerParametrize.fontFamily')}
                            </h3>
                            <p className="mt-1 truncate text-sm text-gray-500">{layer?.fontFamily}</p>
                          </div>
                        )}
                        {layer?.fontFamily && (
                          <div className="sm:col-span-1">
                            <h3 className="block text-sm font-medium text-gray-900">
                              {t('components.project.template.layer.LayerParametrize.fontName')}
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">{layer?.font}</p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {/* Composition combobox */}
                  <div>
                    <label htmlFor="composition" className="block text-sm font-medium text-gray-900">
                      {t('components.project.template.layer.LayerParametrize.compositionsChange')}
                    </label>
                    <div className="mt-1">
                      <select
                        id="composition"
                        name="composition"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={composition}
                        onChange={e => setComposition(e.target.value)}
                      >
                        <option value={allCompositionsIndex}>
                          {t('components.project.template.layer.LayerParametrize.compositionsAllCompositions')}
                        </option>
                        {item?.compositions?.map((c, index) => (
                          <option key={`comp-opt-${index}`} value={index}>
                            {c.map(cmp => cmp.name).join(' > ')}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* Effect combobox */}
                  {!isEmpty(effects) && (
                    <div>
                      <label htmlFor="effect" className="block text-sm font-medium text-gray-900">
                        {t('components.project.template.layer.LayerParametrize.applyForEffect')}
                      </label>
                      <div className="mt-1">
                        <select
                          id="effect"
                          name="effect"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={effectPropertyName}
                          onChange={e => setEffectPropertyName(e.target.value === '-' ? undefined : e.target.value)}
                        >
                          <option value={undefined}>-</option>
                          {effects.map((e, index) => (
                            <option key={`effect-opt-${index}`} value={e.propertyName}>
                              <Trans
                                i18nKey={'components.project.template.layer.LayerParametrize.effects.effectType'}
                                context={e.type}
                                tOptions={{ effectName: e.name }}
                              >
                                {e.name}
                              </Trans>
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}

                  <LayerParametrizeTabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    hideParametrization={compositionItem}
                    isEffect={isEffect}
                    numberOfScripts={selectedScripts.length}
                  />
                  <LayerParametrizeForm
                    visible={visible}
                    setParamValidation={setParamValidation}
                    identifier={identifier}
                    setIdentifier={setIdentifier}
                    setParametrization={setParametrization}
                    editLayer={item}
                    isActiveTab={activeTab === LayerParametrizeTabsValue.TEMPLATE_VALUE}
                    autoScaleMedia={autoScaleMediaExists}
                    autoScaleText={autoScaleTextExists}
                    setAutoScaleMedia={onAutoScaleChangeMedia}
                    setAutoScaleText={onAutoScaleChangeText}
                    isEffect={isEffect}
                  />
                  <LayerParametrizeScripting
                    setShowScriptingModal={setShowScriptingModal}
                    isActiveTab={activeTab === LayerParametrizeTabsValue.SCRIPTING}
                    scripts={selectedScripts}
                    editLayerScripts={item?.plainlyLayer?.scripting?.scripts}
                    setScripts={setSelectedScripts}
                    setScriptValidation={setScriptValidation}
                    setEditScript={setEditScript}
                    onDragAndDrop={onDragAndDrop}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex shrink-0 justify-end px-4 py-4">
            <Button secondary onClick={close}>
              {t('general.action.cancel')}
            </Button>
            <Button type="submit" className="ml-4" disabled={!canSave}>
              {t('general.action.save')}
            </Button>
          </div>
        </form>
      </WideSlideover>
      {item && (
        <LayerParametrizeScriptingModal
          show={showScriptingModal}
          closeModal={closeModal}
          selectedScripts={selectedScripts}
          setSelectedScripts={setSelectedScripts}
          editScript={editScript}
          compositions={getComposition()}
          item={item}
        />
      )}
    </>
  );
};
