import { AnyJobError, BackendJobErrorCode, ErrorCode, ScriptErrorCode, WorkerErrorCode } from '@plainly/types';

export const getTroubleshootLink = (code: ErrorCode) => {
  switch (code) {
    case WorkerErrorCode.FontInstallFailed:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#unable-to-install-fonts`;
    case WorkerErrorCode.ConvertAssetsFailed:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#failed-to-convert-assets`;
    case WorkerErrorCode.AfterEffectsError:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#troubleshooting`;
    case WorkerErrorCode.DuplicatedFontsFound:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#duplicate-fonts`;
    case WorkerErrorCode.FontsMismatch:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#missing-fonts`;
    case WorkerErrorCode.PackagedFontsParsingFailed:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#unable-to-parse-packaged-fonts`;
    case WorkerErrorCode.ReadingProjectFontsFailed:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#unable-to-read-project-fonts`;
    case WorkerErrorCode.UnsupportedAeVersion:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#unsupported-after-effects-version`;
    case WorkerErrorCode.DownloadAssetFailed:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#download-assets-failed`;
    case WorkerErrorCode.ImportAssetsFailed:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#import-assets-failed`;
    case WorkerErrorCode.WorkerFatalError:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#troubleshooting`;
    case WorkerErrorCode.FfmpegEncodingError:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#ffmpeg-encoding-error`;
    case WorkerErrorCode.RenderingTimeout:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#troubleshooting`;
    case ScriptErrorCode.ProjectMissingFiles:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#missing-files`;
    case ScriptErrorCode.ActionWrongLayerType:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#script-action-wrong-layer-type`;
    case ScriptErrorCode.RootCompNotAllowed:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#script-root-comp-not-allowed`;
    case ScriptErrorCode.LayerNotFound:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#script-layer-not-found`;
    case ScriptErrorCode.CompositionNotFound:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#script-composition-not-found`;
    case ScriptErrorCode.FontsInvalid:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#fonts-invalid`;
    case ScriptErrorCode.ScriptFatalError:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#troubleshooting`;
    default:
      return `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#troubleshooting`;
  }
};

export const getErrorSolution = (code: WorkerErrorCode | ScriptErrorCode | BackendJobErrorCode) => {
  return `components.common.error.ErrorCard.solutionDesc.${code}`;
};

export const getErrorReason = (code: ErrorCode) => {
  return `general.errorCode.${code}`;
};

export const breakLine = (text: string): string => {
  return text.replace(/\\n/g, '\n');
};

export const getJobError = (error: Record<string, string | object>): AnyJobError | undefined => {
  if (
    error.code &&
    typeof error.code === 'string' &&
    [
      ...Object.values(WorkerErrorCode),
      ...Object.values(ScriptErrorCode),
      ...Object.values(BackendJobErrorCode)
    ].includes(error.code as WorkerErrorCode | ScriptErrorCode | BackendJobErrorCode)
  ) {
    return error as AnyJobError;
  }
};

export const displayContactSupport = (code: ErrorCode): boolean => {
  return (
    code === WorkerErrorCode.AfterEffectsError ||
    code === WorkerErrorCode.WorkerFatalError ||
    code === WorkerErrorCode.RenderingTimeout ||
    code === WorkerErrorCode.UnableToUpgradeAeVersion ||
    code === ScriptErrorCode.ScriptFatalError ||
    code === BackendJobErrorCode.InfrastructureFailure
  );
};
