import { cloneElement, isValidElement, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

export type StyledAProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
  icon?: ReactNode;
  noFlex?: boolean;
  disabled?: boolean;
};

export const StyledA = ({ children, icon, className, noFlex, disabled, ...rest }: StyledAProps) => {
  return (
    <a
      {...rest}
      className={classNames(
        'items-center font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500',
        noFlex ? '' : 'inline-flex',
        className && className,
        disabled && 'pointer-events-none opacity-50'
      )}
    >
      {icon &&
        isValidElement(icon) &&
        cloneElement(icon as ReactElement, {
          className: classNames('h-5 w-5')
        })}
      {children}
    </a>
  );
};
