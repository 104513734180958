import { includesSafe } from '@src/utils';

import { PlainlyPackage, Subscription, SubscriptionIdentifier, SubscriptionStatus } from '..';

export const useV2Packages = true;

const isInternallyManagedSubscription = (subscription?: Subscription): boolean => {
  return subscription?.internallyManaged === true;
};

export const isActiveSubscription = (subscription?: Subscription): boolean => {
  return subscription?.active === true;
};

export const isCustomPlan = (subscription: Subscription): boolean => {
  return isInternallyManagedSubscription(subscription) && PlainlyPackage.FREE !== subscription.plainlyPackage;
};

export const isExternalSubscription = (subscription: Subscription): boolean => {
  return !isInternallyManagedSubscription(subscription) && PlainlyPackage.FREE !== subscription.plainlyPackage;
};

export const isV1Subscription = (subscription: Subscription): boolean => {
  return includesSafe(
    [
      SubscriptionIdentifier.STARTER,
      SubscriptionIdentifier.STARTER_YEARLY,
      SubscriptionIdentifier.TEAM,
      SubscriptionIdentifier.TEAM_YEARLY,
      SubscriptionIdentifier.PRO,
      SubscriptionIdentifier.PRO_YEARLY
    ],
    subscription.subscriptionIdentifier
  );
};

export const canCreateSubscription = (subscription: Subscription): boolean => {
  const inactiveSubscription = !isActiveSubscription(subscription);
  return (
    inactiveSubscription ||
    includesSafe([SubscriptionStatus.CANCELLED, SubscriptionStatus.TRAILING], subscription.status)
  );
};

export const canDowngradeSubscription = (subscription: Subscription): boolean => {
  const externalSubscription = isExternalSubscription(subscription);
  const downgradePackage = includesSafe(
    [PlainlyPackage.EXPLORER, PlainlyPackage.TEAM, PlainlyPackage.PRO],
    subscription.plainlyPackage
  );
  return externalSubscription && downgradePackage;
};

export const canUpgradeSubscription = (subscription: Subscription): boolean => {
  const externalSubscription = isExternalSubscription(subscription);
  const canSubscribe = canCreateSubscription(subscription);
  if (!externalSubscription || canSubscribe) {
    return false;
  }

  const upgradePackage = includesSafe(
    [PlainlyPackage.STARTER, PlainlyPackage.EXPLORER, PlainlyPackage.TEAM],
    subscription.plainlyPackage
  );

  // allowed if upgrade is possible,
  if (upgradePackage) {
    return true;
  }

  // or if we are using v2 packages and the subscription is v1
  if (useV2Packages) {
    return isV1Subscription(subscription);
  }

  return false;
};

export const canCancelSubscription = (subscription: Subscription): boolean => {
  const externalSubscription = isExternalSubscription(subscription);
  const activeSubscription = isActiveSubscription(subscription);
  return (
    externalSubscription &&
    activeSubscription &&
    subscription.status !== SubscriptionStatus.CANCELLED &&
    !!subscription.cancelUrl
  );
};

export const canUpdateSubscriptionPayment = (subscription: Subscription): boolean => {
  const externalSubscription = isExternalSubscription(subscription);
  const activeSubscription = isActiveSubscription(subscription);
  return (
    externalSubscription &&
    activeSubscription &&
    subscription.status !== SubscriptionStatus.CANCELLED &&
    !!subscription.updateUrl
  );
};

export const getPaddleSubscriptionId = (s: SubscriptionIdentifier) => {
  switch (s) {
    // monthly ones
    case SubscriptionIdentifier.STARTER:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_STARTER);
    case SubscriptionIdentifier.TEAM:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_TEAM);
    case SubscriptionIdentifier.PRO:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_PRO);

    // yearly ones
    case SubscriptionIdentifier.STARTER_YEARLY:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_STARTER_YEARLY);
    case SubscriptionIdentifier.TEAM_YEARLY:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_TEAM_YEARLY);
    case SubscriptionIdentifier.PRO_YEARLY:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_PRO_YEARLY);

    // v2
    case SubscriptionIdentifier.STARTER_V2:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_STARTER_V2);
    case SubscriptionIdentifier.EXPLORER_V2:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_EXPLORER_V2);
    case SubscriptionIdentifier.TEAM_V2:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_TEAM_V2);
    case SubscriptionIdentifier.PRO_V2:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_PRO_V2);

    // v2 yearly
    case SubscriptionIdentifier.STARTER_V2_YEARLY:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_STARTER_V2_YEARLY);
    case SubscriptionIdentifier.EXPLORER_V2_YEARLY:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_EXPLORER_V2_YEARLY);
    case SubscriptionIdentifier.TEAM_V2_YEARLY:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_TEAM_V2_YEARLY);
    case SubscriptionIdentifier.PRO_V2_YEARLY:
      return parseInt(import.meta.env.VITE_APP_PADDLE_PACKAGE_PRO_V2_YEARLY);
  }
};
