import { useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ColumnSelectField, LayerMngAudioEnabledInput, LayerMngEnabledInput } from '@src/components';
import { LayerManagementParameterDto } from '@src/models';

export type TransformedLayerManagementParameterDto =
  | {
      [K in keyof LayerManagementParameterDto]?: {
        value: NonNullable<LayerManagementParameterDto[K]>;
        isColumnSelect: false;
      };
    }
  | {
      [K in keyof LayerManagementParameterDto]?: {
        value: string;
        isColumnSelect: true;
      };
    };

type BatchProps = {
  batchRender: true;
  parameter: TransformedLayerManagementParameterDto | undefined;
  onChange: (value: string | number | boolean | undefined, property: string, isColumnSelect: boolean) => void;
  isColumnSelect: (property: string) => boolean;
  columns: string[] | undefined;
  isComposition: boolean;
};
type SingleProps = {
  batchRender: false;
  parameter: LayerManagementParameterDto | undefined;
  onChange: (value: { [key: string]: string | number | boolean | undefined }) => void;
  isColumnSelect?: never;
  columns?: never;
  isComposition: boolean;
};
type DynamicScriptLayerManagementProps = BatchProps | SingleProps;

export const DynamicScriptLayerManagement = ({
  batchRender,
  parameter,
  onChange,
  isColumnSelect,
  columns,
  isComposition
}: DynamicScriptLayerManagementProps) => {
  const { t } = useTranslation();

  const undefinedCallback = useCallback(
    (property: string, isColumnSelect: boolean) => {
      batchRender === true ? onChange(undefined, property, isColumnSelect) : onChange({ [property]: undefined });
    },
    [batchRender, onChange]
  );

  const handleNumberInput = useCallback(
    (property: string, value: string, isColumnSelect: boolean, float = false) => {
      // handle string first of isColumnSelect
      if (isColumnSelect === true && batchRender === true) {
        onChange(value, property, true);
      } else {
        // we are in input mode
        if (value === '') {
          undefinedCallback(property, isColumnSelect);
        } else {
          try {
            const pValue = float ? parseFloat(value) : parseInt(value);
            if (!isNaN(pValue)) {
              batchRender === true ? onChange(pValue, property, isColumnSelect) : onChange({ [property]: pValue });
            }
          } catch {
            undefinedCallback(property, isColumnSelect);
          }
        }
      }
    },
    [batchRender, onChange, undefinedCallback]
  );

  return (
    <ul
      className={classNames(
        'space-y-2 sm:grid sm:grid-cols-2 sm:gap-x-2 sm:gap-y-2 sm:space-y-0',
        batchRender && 'text-gray-500 lg:grid-cols-3 lg:gap-x-4'
      )}
    >
      <li>
        <ColumnSelectField
          id="duration"
          value={batchRender === true ? parameter?.duration?.value : parameter?.duration}
          onFieldUpdate={(value, isColumnSelect) => handleNumberInput('duration', value, isColumnSelect, true)}
          options={columns || []}
          columnSelect={isColumnSelect?.('duration') || false}
          excludeSwitchLink={!batchRender}
          label={t('general.common.durationLabel')}
          type="number"
          step="any"
          min={0}
        />
      </li>

      <li>
        <ColumnSelectField
          id="startTime"
          value={batchRender === true ? parameter?.startTime?.value : parameter?.startTime}
          onFieldUpdate={(value, isColumnSelect) => handleNumberInput('startTime', value, isColumnSelect, true)}
          options={columns || []}
          columnSelect={isColumnSelect?.('startTime') || false}
          excludeSwitchLink={!batchRender}
          label={t('components.render.dynamicScripts.startTime')}
          type="number"
          step="any"
        />
      </li>

      {!isComposition && (
        <>
          <li>
            <ColumnSelectField
              id="font"
              value={batchRender === true ? parameter?.font?.value : parameter?.font}
              onFieldUpdate={(value, isColumnSelect) => {
                batchRender === true
                  ? onChange(value ? value : undefined, 'font', isColumnSelect)
                  : onChange({ font: value ? value : undefined });
              }}
              options={columns || []}
              columnSelect={isColumnSelect?.('font') || false}
              excludeSwitchLink={!batchRender}
              label={t('components.render.dynamicScripts.font')}
            />
          </li>

          <li>
            <ColumnSelectField
              id="fontUrl"
              value={batchRender === true ? parameter?.fontUrl?.value : parameter?.fontUrl}
              onFieldUpdate={(value, isColumnSelect) => {
                batchRender === true
                  ? onChange(value ? value : undefined, 'fontUrl', isColumnSelect)
                  : onChange({ fontUrl: value ? value : undefined });
              }}
              options={columns || []}
              columnSelect={isColumnSelect?.('fontUrl') || false}
              excludeSwitchLink={!batchRender}
              label={t('components.render.dynamicScripts.fontUrl')}
            />
          </li>

          <li>
            <ColumnSelectField
              id="fontSize"
              value={batchRender === true ? parameter?.fontSize?.value : parameter?.fontSize}
              onFieldUpdate={(value, isColumnSelect) => handleNumberInput('fontSize', value, isColumnSelect)}
              options={columns || []}
              columnSelect={isColumnSelect?.('fontSize') || false}
              excludeSwitchLink={!batchRender}
              label={t('components.render.dynamicScripts.fontSize')}
              type="number"
              min={1}
            />
          </li>
        </>
      )}

      <li>
        <LayerMngEnabledInput
          value={batchRender === true ? parameter?.enabled?.value : parameter?.enabled}
          onFieldUpdate={({ value, isColumnSelect }) => {
            if (batchRender === true) onChange(value, 'enabled', isColumnSelect);
            if (batchRender === false) onChange({ enabled: value });
          }}
          isColumnSelect={isColumnSelect?.('enabled') || false}
          columns={columns}
        />
      </li>

      <li>
        <LayerMngAudioEnabledInput
          value={batchRender === true ? parameter?.audioEnabled?.value : parameter?.audioEnabled}
          onFieldUpdate={({ value, isColumnSelect }) => {
            if (batchRender === true) onChange(value, 'audioEnabled', isColumnSelect);
            if (batchRender === false) onChange({ audioEnabled: value });
          }}
          isColumnSelect={isColumnSelect?.('audioEnabled') || false}
          columns={columns}
        />
      </li>

      {isComposition && (
        <>
          <li>
            <ColumnSelectField
              id="width"
              value={batchRender === true ? parameter?.width?.value : parameter?.width}
              onFieldUpdate={(value, isColumnSelect) => handleNumberInput('width', value, isColumnSelect, true)}
              options={columns || []}
              columnSelect={isColumnSelect?.('width') || false}
              excludeSwitchLink={!batchRender}
              label={t('general.common.widthLabel')}
              type="number"
              step={1}
              min={1}
            />
          </li>

          <li>
            <ColumnSelectField
              id="height"
              value={batchRender === true ? parameter?.height?.value : parameter?.height}
              onFieldUpdate={(value, isColumnSelect) => handleNumberInput('height', value, isColumnSelect, true)}
              options={columns || []}
              columnSelect={isColumnSelect?.('height') || false}
              excludeSwitchLink={!batchRender}
              label={t('general.common.heightLabel')}
              type="number"
              step={1}
              min={1}
            />
          </li>
        </>
      )}
    </ul>
  );
};
