import { memo, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { SignalIcon } from '@heroicons/react/20/solid';

import { StatusContext } from '.';

export const StatusBadge = memo(function StatusBadge() {
  const { fetched, status } = useContext(StatusContext);
  const { t } = useTranslation();

  return (
    <>
      {!fetched && <SignalIcon className="h-4 w-4 animate-spin" />}
      {fetched && status && (
        <>
          <span className="truncate">{t('components.common.status', { context: status })}</span>
          <div
            className={classNames(
              'flex-none rounded-full',
              status === 'up' && 'bg-green-400/10 p-1 text-green-400',
              status === 'incident' && 'bg-orange-400/10 p-1 text-orange-400',
              status === 'outage' && 'bg-red-400/10 p-1 text-red-400',
              status === 'maintenance' && 'bg-yellow-400/10 p-1 text-yellow-400'
            )}
          >
            <div className="h-2 w-2 rounded-full bg-current" />
          </div>
        </>
      )}
    </>
  );
});
