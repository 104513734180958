import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { useUpgradeSubscriptionPlan } from '@src/hooks';
import { PlainlyPackage, Subscription, SubscriptionIdentifier } from '@src/models';
import { useV2Packages } from '@src/models/subscription/utils';
import { includesSafe } from '@src/utils';

import { Alert, Button, ErrorAlert, WideSlideover } from '../common';

import {
  SubscriptionPackageExplorerV2,
  SubscriptionPackageProV1,
  SubscriptionPackageProV2,
  SubscriptionPackageStarterV1,
  SubscriptionPackageStarterV2,
  SubscriptionPackageTeamV1,
  SubscriptionPackageTeamV2
} from './SubscriptionPackage';

type SubscriptionExistingPlanUpgradeProps = {
  visible: boolean;
  onClose: () => void;
  subscription: Subscription;
};

export const SubscriptionExistingPlanUpgrade = ({
  visible,
  onClose,
  subscription
}: SubscriptionExistingPlanUpgradeProps) => {
  const { t } = useTranslation();
  const [selectedPackage, setSelectedPackage] = useState<SubscriptionIdentifier | undefined>(undefined);
  const [upgradeSuccess, setUpgradeSuccess] = useState<boolean>(false);
  const showYearlyPackages = subscription.billingInterval === 'YEARLY';

  const { isLoading: upgradeStarted, mutateAsync: upgradePlan, error } = useUpgradeSubscriptionPlan();

  // reset state and close
  const resetAndClose = () => {
    setSelectedPackage(undefined);
    setUpgradeSuccess(false);
    onClose();
  };

  const onUpgrade = (selectedPackage: SubscriptionIdentifier) => {
    if (selectedPackage) {
      upgradePlan({ subscriptionIdentifier: selectedPackage }).then(response => {
        if (!response) return;
        setSelectedPackage(undefined);
        setUpgradeSuccess(true);
      });
    }
  };

  const starterPackages = [PlainlyPackage.STARTER];
  const explorerPackages = [...starterPackages, PlainlyPackage.EXPLORER];
  const teamPackages = [...explorerPackages, PlainlyPackage.TEAM];
  const proPackages = [...teamPackages, PlainlyPackage.PRO];

  const showStarter = includesSafe(starterPackages, subscription.plainlyPackage);
  const showExplorer = includesSafe(explorerPackages, subscription.plainlyPackage);
  const showTeam = includesSafe(teamPackages, subscription.plainlyPackage);
  const showPro = includesSafe(proPackages, subscription.plainlyPackage);

  return (
    <WideSlideover
      visible={visible}
      onClose={resetAndClose}
      title={t('components.user.SubscriptionPlanUpgrade.title')}
      subtitle={t('components.user.SubscriptionExistingPlanUpgrade.subtitle')}
    >
      <>
        {visible && <div className="checkout-container" />}
        <div className="space-y-6 overflow-y-scroll px-4 py-5 sm:p-6">
          {!upgradeSuccess && !selectedPackage && (
            <>
              {!showYearlyPackages && (
                <>
                  {!useV2Packages && (
                    <>
                      {subscription?.subscriptionIdentifier === SubscriptionIdentifier.STARTER && (
                        <SubscriptionPackageStarterV1
                          onUpgrade={setSelectedPackage}
                          selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.STARTER}
                        />
                      )}
                      {subscription?.subscriptionIdentifier !== SubscriptionIdentifier.PRO && (
                        <SubscriptionPackageTeamV1
                          onUpgrade={setSelectedPackage}
                          selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.TEAM}
                        />
                      )}
                      <SubscriptionPackageProV1
                        onUpgrade={setSelectedPackage}
                        selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.PRO}
                      />
                    </>
                  )}
                  {useV2Packages && (
                    <>
                      {subscription?.subscriptionIdentifier === SubscriptionIdentifier.STARTER && (
                        <SubscriptionPackageStarterV1 onUpgrade={() => {}} selected={true} />
                      )}
                      {subscription?.subscriptionIdentifier === SubscriptionIdentifier.TEAM && (
                        <SubscriptionPackageTeamV1 onUpgrade={() => {}} selected={true} />
                      )}
                      {subscription?.subscriptionIdentifier === SubscriptionIdentifier.PRO && (
                        <SubscriptionPackageProV1 onUpgrade={() => {}} selected={true} />
                      )}
                      {showStarter && (
                        <SubscriptionPackageStarterV2
                          onUpgrade={setSelectedPackage}
                          selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.STARTER_V2}
                        />
                      )}
                      {showExplorer && (
                        <SubscriptionPackageExplorerV2
                          onUpgrade={setSelectedPackage}
                          selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.EXPLORER_V2}
                        />
                      )}
                      {showTeam && (
                        <SubscriptionPackageTeamV2
                          onUpgrade={setSelectedPackage}
                          selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.TEAM_V2}
                        />
                      )}
                      {showPro && (
                        <SubscriptionPackageProV2
                          onUpgrade={setSelectedPackage}
                          selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.PRO_V2}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              {showYearlyPackages && (
                <>
                  {!useV2Packages && (
                    <>
                      {subscription?.subscriptionIdentifier === SubscriptionIdentifier.STARTER_YEARLY && (
                        <SubscriptionPackageStarterV1
                          yearly
                          onUpgrade={setSelectedPackage}
                          selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.STARTER_YEARLY}
                        />
                      )}
                      {subscription?.subscriptionIdentifier !== SubscriptionIdentifier.PRO_YEARLY && (
                        <SubscriptionPackageTeamV1
                          yearly
                          onUpgrade={setSelectedPackage}
                          selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.TEAM_YEARLY}
                        />
                      )}
                      <SubscriptionPackageProV1
                        yearly
                        onUpgrade={setSelectedPackage}
                        selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.PRO_YEARLY}
                      />
                    </>
                  )}
                  {useV2Packages && (
                    <>
                      {subscription?.subscriptionIdentifier === SubscriptionIdentifier.STARTER_YEARLY && (
                        <SubscriptionPackageStarterV1 onUpgrade={() => {}} selected={true} />
                      )}
                      {subscription?.subscriptionIdentifier === SubscriptionIdentifier.TEAM_YEARLY && (
                        <SubscriptionPackageTeamV1 onUpgrade={() => {}} selected={true} />
                      )}
                      {subscription?.subscriptionIdentifier === SubscriptionIdentifier.PRO_YEARLY && (
                        <SubscriptionPackageProV1 onUpgrade={() => {}} selected={true} />
                      )}
                      {showStarter && (
                        <SubscriptionPackageStarterV2
                          yearly
                          onUpgrade={setSelectedPackage}
                          selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.STARTER_V2_YEARLY}
                        />
                      )}
                      {showExplorer && (
                        <SubscriptionPackageExplorerV2
                          yearly
                          onUpgrade={setSelectedPackage}
                          selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.EXPLORER_V2_YEARLY}
                        />
                      )}
                      {showTeam && (
                        <SubscriptionPackageTeamV2
                          yearly
                          onUpgrade={setSelectedPackage}
                          selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.TEAM_V2_YEARLY}
                        />
                      )}
                      {showPro && (
                        <SubscriptionPackageProV2
                          yearly
                          onUpgrade={setSelectedPackage}
                          selected={subscription?.subscriptionIdentifier === SubscriptionIdentifier.PRO_V2_YEARLY}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          {selectedPackage && (
            <ConfirmSelectedPackage
              selectedPackage={selectedPackage}
              onUpgrade={() => onUpgrade(selectedPackage)}
              handleBack={() => setSelectedPackage(undefined)}
              upgradeStarted={upgradeStarted}
            />
          )}
          {selectedPackage && !!error && <ErrorAlert error={error} closable={true} />}
          {upgradeSuccess && (
            <Alert
              type="success"
              show={true}
              alertContent={t('components.user.SubscriptionPlanUpgrade.upgradeSuccess')}
            />
          )}
        </div>
      </>
    </WideSlideover>
  );
};

type ConfirmSelectedPackageProps = {
  selectedPackage: SubscriptionIdentifier;
  onUpgrade?: () => void;
  handleBack: () => void;
  upgradeStarted: boolean;
};

const ConfirmSelectedPackage = ({
  selectedPackage,
  onUpgrade,
  handleBack,
  upgradeStarted
}: ConfirmSelectedPackageProps) => {
  const { t } = useTranslation();
  const getPrice = () => {
    switch (selectedPackage) {
      case SubscriptionIdentifier.STARTER:
        return '59';
      case SubscriptionIdentifier.TEAM:
        return '249';
      case SubscriptionIdentifier.PRO:
        return '599';
    }
  };

  return (
    <div className="bg-white sm:rounded-lg">
      <div>
        <div className="sm:flex sm:items-start sm:justify-between">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t('components.user.SubscriptionPlanUpgrade.ConfirmSelectedPackage.title')}
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>{t('components.user.SubscriptionPlanUpgrade.ConfirmSelectedPackage.subtitle')}</p>
            </div>
          </div>
        </div>
        <div>
          <ul className="mt-6 space-y-4">
            <li className="flex space-x-3">
              <span className="flex text-sm text-gray-500">
                <ArrowRightIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
                {t('components.user.SubscriptionPlanUpgrade.ConfirmSelectedPackage.term1')}
              </span>
            </li>
            <li className="flex space-x-3">
              <span className="flex text-sm text-gray-500">
                <ArrowRightIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
                {t('components.user.SubscriptionPlanUpgrade.ConfirmSelectedPackage.term2', { price: getPrice() })}
              </span>
            </li>
            <li className="flex space-x-3">
              <span className="flex text-sm text-gray-500">
                <ArrowRightIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
                {t('components.user.SubscriptionPlanUpgrade.ConfirmSelectedPackage.term3')}
              </span>
            </li>
          </ul>
        </div>
        <div className="mt-5 flex justify-end">
          <Button secondary onClick={handleBack}>
            {t('general.action.back')}
          </Button>
          <Button className="ml-2" onClick={onUpgrade} loading={upgradeStarted} disabled={upgradeStarted}>
            {t('general.action.upgrade')}
          </Button>
        </div>
      </div>
    </div>
  );
};
