import { useCallback, useMemo, useState } from 'react';

export const useValidateUrls = () => {
  const [invalidUrls, setInvalidUrls] = useState(new Map<string, string>());
  const [validating, setValidating] = useState(false);

  const handleInvalidUrls = useCallback(
    (key: string) => (url: string, valid: boolean, validating: boolean) => {
      if (validating) {
        setValidating(true);
        return;
      } else {
        setValidating(false);
      }

      if (valid) {
        if (invalidUrls.has(key)) {
          const newInvalidUrls = new Map(invalidUrls);
          newInvalidUrls.delete(key);
          setInvalidUrls(newInvalidUrls);
        }
      } else {
        if (!invalidUrls.has(key)) {
          setInvalidUrls(prev => new Map(prev.set(key, url)));
        }
      }
    },
    [invalidUrls]
  );

  const urlsValid: boolean = useMemo(() => invalidUrls.size === 0 && !validating, [invalidUrls.size, validating]);
  return { urlsValid, handleInvalidUrls };
};
