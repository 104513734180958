import { memo, useMemo } from 'react';
import { ProductFruits } from 'react-product-fruits';

import { useGetProjects, useGetRenders } from '@src/hooks';
import { Subscription, UserDto, UserProductFruitsProps } from '@src/models';
import {
  getProductFruitsBasicIdentification,
  getProductFruitsProjectsProps,
  getProductFruitsRendersProps,
  getProductFruitsSubscriptionProps,
  getProductFruitsUserProps
} from '@src/tools/productfruits';

export const ProductFruitsWrapper = memo(function ProductFruitsWrapper({
  user,
  subscription
}: {
  user: UserDto;
  subscription: Subscription;
}) {
  // additional data
  const { data: projects } = useGetProjects();
  const { data: renders } = useGetRenders({});

  // update function for the product fruits props
  const productFruitsProps: Partial<UserProductFruitsProps> = useMemo(() => {
    return {
      ...getProductFruitsUserProps(user),
      ...(subscription ? getProductFruitsSubscriptionProps(subscription) : {}),
      ...(projects ? getProductFruitsProjectsProps(projects) : {}),
      ...(renders ? getProductFruitsRendersProps(renders) : {})
    };
  }, [user, subscription, projects, renders]);

  return (
    <>
      {!!user.productFruitsHmac && (
        <ProductFruits
          workspaceCode={import.meta.env.VITE_APP_PRODUCT_FRUITS_WORKSPACE}
          language="en"
          user={{
            ...getProductFruitsBasicIdentification(user.user),
            hmac: {
              hash: user.productFruitsHmac
            },
            props: productFruitsProps
          }}
        />
      )}
    </>
  );
});
