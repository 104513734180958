import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  ArrowUpTrayIcon,
  CheckBadgeIcon as ValidUrlIcon,
  EllipsisVerticalIcon as LoadingIcon,
  EyeIcon as PreviewIcon,
  XCircleIcon as InvalidUrlIcon
} from '@heroicons/react/20/solid';
import { FilestackUpload } from '@src/components';
import { useDebouncedValue, useNotifications, useValidateAsset } from '@src/hooks';

export type MediaInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  onFieldUpdate: (value: string) => void;
  onValidation?: (url: string, valid: boolean, validating: boolean) => void;
  errors?: string[];
};

const isAbsUrl = (url: string) => url.indexOf('://') > 0 || url.indexOf('//') === 0;

export const MediaInput = ({ disabled, className, value, onFieldUpdate, onValidation, ...rest }: MediaInputProps) => {
  const { t } = useTranslation();
  const { notifyError } = useNotifications();
  const url = value?.toString() || '';
  const debouncedUrl = useDebouncedValue(url, 500);
  const [isValidUrl, setIsValidUrl] = useState<boolean | undefined>(undefined);
  const [uploadOpen, setUploadOpen] = useState(false);

  const { isLoading: isValidating, mutateAsync: validateMedia } = useValidateAsset();

  const validation = useCallback(
    async (value: string) => {
      if (!value) {
        onValidation?.(value, true, false);
        setIsValidUrl(undefined);
        return;
      }

      if (isAbsUrl(value)) {
        // reset validation state
        onValidation?.(value, false, true);
        setIsValidUrl(undefined);
        try {
          const valid = await validateMedia({ url: value });
          onValidation?.(value, valid.valid, false);
          setIsValidUrl(valid.valid);
        } catch {
          onValidation?.(value, false, false);
          setIsValidUrl(false);
        }
      } else {
        onValidation?.(value, false, false);
        setIsValidUrl(false);
      }
    },
    [onValidation, validateMedia]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.trim();
      onFieldUpdate(value);
    },
    [onFieldUpdate]
  );

  // We need to perform validation here because of the initial render when we have "Default" value
  // thus, to prevent duplicate checks, we can just use useEffect for validation, and not in handle changes
  // and we need to do this only when the url changes, which is always when change is made
  useEffect(() => {
    validation(debouncedUrl);
  }, [debouncedUrl]);

  return (
    <div className={classNames('relative flex rounded-md shadow-sm', className)}>
      <div className="relative flex flex-grow items-stretch focus-within:z-10">
        <input
          className={classNames(
            'block w-full rounded-none rounded-l-md border-gray-300 text-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50',
            isValidUrl ? 'pr-16' : 'pr-10'
          )}
          type="url"
          value={value || ''}
          autoComplete="off"
          onInput={handleChange}
          {...rest}
          disabled={disabled}
        />
        {isValidating && (
          <div className="pointer-events-none absolute inset-y-0 right-0 mr-3 flex items-center">
            <LoadingIcon className={classNames('h-5 w-5 animate-spin text-indigo-600')} />
          </div>
        )}
        {!isValidating && (
          <>
            {isValidUrl === false && value && (
              <div className="pointer-events-none absolute inset-y-0 right-0 mr-3 flex items-center">
                <InvalidUrlIcon className="h-5 w-5 text-red-400" />
              </div>
            )}

            {isValidUrl && value && (
              <>
                <div className="pointer-events-none absolute inset-y-0 right-0 mr-3 flex items-center">
                  <ValidUrlIcon className="h-5 w-5 text-green-400" />
                </div>
                <div className="absolute inset-y-0 right-5 mr-4 flex cursor-pointer items-center">
                  <PreviewIcon className="h-5 w-5 text-gray-400" onClick={() => url && window.open(url, '_blank')} />
                </div>
              </>
            )}
          </>
        )}
      </div>
      <button
        type="button"
        disabled={disabled || uploadOpen}
        onClick={() => setUploadOpen(true)}
        className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50"
      >
        <ArrowUpTrayIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        <span>{t('general.action.upload')}</span>
      </button>
      <FilestackUpload
        uploadOpen={uploadOpen}
        onClose={() => setUploadOpen(false)}
        onFieldUpdate={onFieldUpdate}
        onFileUploadFailed={() => {
          notifyError(t('components.common.input.FilestackUpload.error'));
        }}
      />
    </div>
  );
};
