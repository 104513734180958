import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { decode, encode } from 'js-base64';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { ChevronDoubleDownIcon, ChevronDoubleUpIcon, FolderMinusIcon } from '@heroicons/react/24/outline';
import {
  Button,
  ButtonCopyCurl,
  DesignFormField,
  DynamicScriptSection,
  getDynamicScripts,
  HelpNotification,
  ProjectCombobox,
  RenderAdvancedOptionsForm,
  RenderFormHeader,
  RenderFormHelpLinks,
  RequiredMarker,
  SwitchButton,
  TemplateCombobox
} from '@src/components';
import {
  useAnyProjectItemReducer,
  useFireRender,
  useNotifications,
  useQueryParams,
  useRenderCurl,
  useValidateUrls
} from '@src/hooks';
import { Layer, ProjectRenderDto, RenderOptionsDto, RenderParameters } from '@src/models';
import { RENDER_BATCH_CSV_PROJECT, RENDER_DETAILS } from '@src/routes';
import {
  convertDotsToNested,
  getSampleData,
  isEmpty,
  isEmptyObj,
  layerToParameter,
  toApiParameterName
} from '@src/utils';

import { validate as validateLayer, ValidationResult } from '../project/template/layer/input/validators';

const EmptyForm = memo(function EmptyForm() {
  const { t } = useTranslation();
  return (
    <div className="w-100 flex min-h-[20vh] items-center justify-center text-sm">
      {t('components.render.common.emptyForm')}
    </div>
  );
});

const EmptyTemplate = memo(function EmptyTemplate() {
  const { t } = useTranslation();
  return (
    <div className="relative flex h-full w-full flex-col justify-center rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
      <FolderMinusIcon className="mx-auto h-8 w-8 text-gray-400" />
      <span className="mt-2 block text-sm font-semibold text-gray-900">
        {t('components.render.RenderForm.emptyTemplate')}
      </span>
    </div>
  );
});

export const RenderForm = memo(function RenderForm() {
  const navigate = useNavigate();
  const back = useCallback(() => navigate(-1), [navigate]);

  const { t } = useTranslation();
  const { notifyInfo } = useNotifications();
  const { searchQuery, withQueryParams } = useQueryParams();
  const location = useLocation();

  const [showAdvancedOptions, setShowAdvancedOptions] = useState<boolean>(false);
  const [showSampleData, setShowSampleData] = useState<string[]>([]);
  const [state, updateProjectItem, updateRenderItem] = useAnyProjectItemReducer();
  const { projectItem: selectedProject, renderItem: selectedTemplate } = useMemo(() => state, [state]);

  const [render, setRender] = useState<ProjectRenderDto>({
    projectId: selectedProject?.id || '',
    parameters: {}
  });
  const { urlsValid, handleInvalidUrls } = useValidateUrls();

  const { curlWithApiKey, curlWithoutApiKey } = useRenderCurl({
    projectId: selectedProject?.id || '',
    templateId: selectedTemplate?.id || '',
    parameters: render?.parameters,
    outputFormat: render?.outputFormat,
    webhook: render.webhook,
    options: render.options,
    attributes: render.attributes
  });

  const initialized = selectedProject && selectedTemplate;
  const parametrizedLayers = useMemo(
    () =>
      (!selectedTemplate?.isDesign &&
        selectedTemplate?.item?.layers.filter(layer => layer.parametrization?.expression)) ||
      [],
    [selectedTemplate]
  );
  const parameters = useMemo(
    () =>
      parametrizedLayers
        .flatMap(l => {
          const mapped = layerToParameter(l);
          return mapped ? [mapped] : [];
        })
        .sort(a => (a?.optional ? 1 : -1)),
    [parametrizedLayers]
  );

  const [formErrors, setFormErrors] = useState<ValidationResult[]>([]);

  const encodedRerenderParams = useMemo(() => searchQuery.get('rerenderParams'), [searchQuery]);
  const encodedRerenderOptions = useMemo(() => searchQuery.get('rerenderOptions'), [searchQuery]);

  // helper constant assignments ⬇

  const dynamicScripts = useMemo(
    () => (!selectedTemplate?.isDesign ? getDynamicScripts(selectedTemplate?.item) : undefined),
    [selectedTemplate]
  );

  const rerenderParams = useMemo(() => {
    return encodedRerenderParams ? JSON.parse(decode(encodedRerenderParams)) : undefined;
  }, [encodedRerenderParams]);

  const defaultParams = useMemo(() => {
    return parameters
      .filter(param => param.defaultValue)
      .reduce((obj, param) => {
        obj[param.key] = param.defaultValue;
        return obj;
      }, {} as RenderParameters);
  }, [parameters]);

  const dynamicParams = useMemo(() => {
    return new Set<string>(dynamicScripts?.map(script => script.parameterName));
  }, [dynamicScripts]);

  const dottedParams = useMemo(() => {
    return new Set<string>(parameters.map(param => param.key).filter(param => param.includes('.')));
  }, [parameters]);

  // end of helper constant assignments ⬆

  // When project and template are chosen, initialize render object with either
  // rerender parameters or parametrized layers' default value
  useEffect(() => {
    if (!initialized) return;

    setShowSampleData([]);
    const renderParameters: RenderParameters = rerenderParams || defaultParams;

    if (rerenderParams && dottedParams.size > 0) {
      // find all dotted parameters inside rerenderParams using the [] notation and add them, remove nested after
      dottedParams.forEach(param => {
        const keys = param.split('.');
        let value: RenderParameters | undefined = renderParameters;

        keys.forEach((key, index) => {
          if (value && typeof value === 'object' && key in value) {
            if (index + 1 === keys.length) {
              const finalValue = value[key];
              if (typeof finalValue === 'string') {
                renderParameters[param] = finalValue;
                delete value[key];
              }
            } else {
              value = value[key];
            }
          } else {
            value = undefined;
          }
        });
      });
    }

    const finalRenderParameters = { ...renderParameters, ...dynamicParams };

    const rerenderOptions: RenderOptionsDto = {};
    const isTemplate = !selectedTemplate.isDesign;

    if (isTemplate) {
      rerenderOptions.outputFormat = selectedTemplate.item.defaultRenderOptions?.outputFormat;
      rerenderOptions.webhook = selectedTemplate.item.defaultRenderOptions?.webhook;
      rerenderOptions.options = selectedTemplate.item.defaultRenderOptions?.options;
    }

    if (encodedRerenderOptions) {
      const decodedRerenderOptions = JSON.parse(decode(encodedRerenderOptions));

      if (decodedRerenderOptions.outputFormat) rerenderOptions.outputFormat = decodedRerenderOptions.outputFormat;
      if (decodedRerenderOptions.webhook) rerenderOptions.webhook = decodedRerenderOptions.webhook;
      if (decodedRerenderOptions.options) rerenderOptions.options = decodedRerenderOptions.options;
    }

    setRender(prev => ({
      ...prev,
      projectId: selectedProject?.id,
      templateId: selectedTemplate?.id,
      parameters: finalRenderParameters,
      outputFormat: rerenderOptions.outputFormat,
      webhook: rerenderOptions.webhook,
      options: rerenderOptions.options
    }));
  }, [
    defaultParams,
    dottedParams,
    dynamicParams,
    encodedRerenderOptions,
    initialized,
    rerenderParams,
    selectedProject,
    selectedTemplate
  ]);

  // Render action
  const { isLoading, mutateAsync: postRender } = useFireRender();

  const validateForm = useCallback(async (): Promise<boolean> => {
    if (!initialized) return false;

    // Call validation for each field
    const errors = await Promise.all(
      parametrizedLayers.map(l => {
        const layerValue = (render.parameters || {})[toApiParameterName(l.parametrization?.value || '')];
        const mapped = layerToParameter(l);
        return validateLayer(l as Layer, layerValue, mapped?.key);
      })
    );

    setFormErrors(errors);

    return !errors.some(e => !e.isValid());
  }, [initialized, parametrizedLayers, render.parameters]);

  useEffect(() => {
    (async () => {
      const isValid = await validateForm();
      if (isValid) setFormErrors([]);
    })();
  }, [render.parameters, validateForm]);

  const fireRender = useCallback(async () => {
    const isValid = await validateForm();
    if (isValid) {
      let newRender = { ...render };

      if (render.parameters) {
        const convertedRenderParameters = convertDotsToNested(render.parameters);
        newRender = { ...render, parameters: convertedRenderParameters };
      }

      const response = await postRender(newRender);
      if (!response) {
        return;
      }

      // Handle successful submission
      notifyInfo(t('components.render.RenderForm.renderSubmitted'));
      navigate(generatePath(RENDER_DETAILS, { id: response.id }));
    }
  }, [validateForm, render, postRender, notifyInfo, navigate, t]);

  const onFormFieldUpdate = useCallback(
    (key: string, value: string | boolean) => {
      const newRender = {
        ...render,
        parameters: {
          ...render.parameters,
          [key]: value
        }
      };

      if (!value) {
        delete newRender.parameters[key];
      }

      delete showSampleData[showSampleData.indexOf(key)];
      setRender(newRender);
    },
    [render, showSampleData]
  );

  const onDynamicScriptUpdate = useCallback(
    (parameterName: string, value: { [key: string]: string | number | boolean | readonly string[] | undefined }) => {
      const newRender = {
        ...render,
        parameters: {
          ...render.parameters,
          [parameterName]: {
            ...render.parameters?.[parameterName],
            ...value
          }
        }
      };

      // if value from value.key is undefined, delete it
      Object.keys(value).forEach(key => {
        if (value[key] === undefined) {
          delete newRender.parameters[parameterName][key];
        }
      });

      // if entire parameter is empty, delete it
      if (isEmptyObj(newRender.parameters[parameterName])) {
        delete newRender.parameters[parameterName];
      }

      setRender(newRender);
    },
    [render]
  );

  const canRender = render && render.projectId && urlsValid;

  const onBatchRender = useCallback(() => {
    navigate(
      withQueryParams(
        generatePath(RENDER_BATCH_CSV_PROJECT, {
          projectId: selectedProject?.id,
          templateId: selectedTemplate?.id,
          step: '1'
        }),
        {
          defaultParameters: !isEmptyObj(render.parameters) ? encode(JSON.stringify(render.parameters)) : undefined
        }
      )
    );
  }, [navigate, render.parameters, selectedProject?.id, selectedTemplate?.id, withQueryParams]);

  const handleSampleDataChange = useCallback(() => {
    if (!isEmpty(showSampleData)) {
      const newRender = { ...render };
      showSampleData.forEach(key => {
        newRender?.parameters && delete newRender.parameters[key];
      });
      setRender(newRender);
      setShowSampleData([]);
    } else {
      const sampleData: Record<string, unknown> = {};
      const sampleDataKeys: string[] = [];
      parameters.forEach(p => {
        const { key } = p;
        if (!render.parameters || !Object.keys(render.parameters).includes(key)) {
          sampleDataKeys.push(key);
          setShowSampleData(sampleDataKeys);
          sampleData[key] = getSampleData(p);
        }
      });
      const newRender = { ...render, parameters: { ...render.parameters, ...sampleData } };
      setShowSampleData(sampleDataKeys);
      setRender(newRender);
    }
  }, [parameters, render, showSampleData]);

  const switchButtonDisabled = useMemo(
    () =>
      showSampleData.length === 0 && !!render.parameters && Object.keys(render.parameters).length === parameters.length,
    [parameters.length, render.parameters, showSampleData.length]
  );

  const fieldFormErrors = useMemo(() => new Map(formErrors.map(e => [e.key, e.errorCodes])), [formErrors]);

  const { ref: submitRef, inView: submitVisible } = useInView();
  const { ref: optionsRef, inView: optionsVisible } = useInView();
  const showScrollToBottom = !showAdvancedOptions && !submitVisible;
  const showScrollToTop = !showAdvancedOptions && submitVisible && !optionsVisible;

  return (
    <div className="flex flex-col space-y-6 md:h-full">
      <div className="sm:rounded-lg sm:p-6">
        <div className="lg:grid lg:grid-cols-5 lg:gap-20">
          <div className="space-y flex flex-col space-y-6 md:col-span-2">
            <div>
              <div className="flex items-center">
                <h3 className="flex text-lg font-medium leading-6 text-gray-900">
                  {t('components.common.action.newRender')}
                </h3>
                <HelpNotification type="badge" links={RenderFormHelpLinks} className="ml-2" />
              </div>
              <p className="mt-1 text-sm text-gray-500">{t('components.render.RenderForm.description')}</p>
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="project-combobox" className="mb-1 block text-sm font-medium text-gray-700">
                {t('general.action.selectProject')}
                <RequiredMarker />
              </label>
              <ProjectCombobox includeDesigns={false} onChange={updateProjectItem} showNotAnalyzed={false} />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="template-combobox" className="mb-1 block text-sm font-medium text-gray-700">
                {t('general.action.selectTemplate')}
                <RequiredMarker />
              </label>
              {!selectedProject?.isDesign && !selectedTemplate?.isDesign && (
                <TemplateCombobox includeDesigns={false} item={selectedProject} onChange={updateRenderItem} />
              )}
            </div>
          </div>
          <div className="mt-5 shadow sm:rounded-md md:col-span-3 lg:mt-0">
            {selectedProject && selectedTemplate && (
              <>
                <RenderFormHeader
                  showAdvancedOptions={showAdvancedOptions}
                  setShowAdvancedOptions={setShowAdvancedOptions}
                  onBatchRender={onBatchRender}
                  optionsRef={optionsRef}
                />
                {showScrollToBottom && (
                  <div className="sticky top-0 z-50 h-0">
                    <a
                      href="#submit"
                      className="absolute right-4 top-6 sm:right-6 md:-right-11 lg:-right-14"
                      title={t('general.action.scrollBottom')}
                    >
                      <Button secondary icon={<ChevronDoubleDownIcon className="h-8 w-8" />} roundedFull />
                    </a>
                  </div>
                )}
                {!showAdvancedOptions && (
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      fireRender();
                    }}
                  >
                    <div className="space-y-6 bg-white px-4 py-6 sm:px-6">
                      <SwitchButton
                        checked={!isEmpty(showSampleData)}
                        onChange={handleSampleDataChange}
                        label={t('components.common.addSampleData')}
                        description={t('components.common.addSampleDataDescription')}
                        disabled={isEmpty(parametrizedLayers) || switchButtonDisabled}
                      />
                      {parameters.length ? (
                        parameters.map(p => (
                          <DesignFormField
                            key={p.key}
                            parameter={p}
                            value={render.parameters?.[p.key]}
                            onChange={onFormFieldUpdate}
                            onValidation={handleInvalidUrls}
                            errors={fieldFormErrors.get(p.key)}
                            required={p.optional === false}
                          />
                        ))
                      ) : (
                        <EmptyForm />
                      )}
                      {dynamicScripts?.map(d => (
                        <DynamicScriptSection
                          key={d.parameterName}
                          renderParameters={render.parameters}
                          parameterName={d.parameterName}
                          scriptType={d.scriptType}
                          onChange={onDynamicScriptUpdate}
                          batchRender={false}
                          isComposition={d.isComposition}
                        />
                      ))}
                    </div>
                    <div className="flex justify-between bg-gray-50 px-4 py-3 sm:px-6" id="submit" ref={submitRef}>
                      <ButtonCopyCurl curlWithApiKey={curlWithApiKey} curlWithoutApiKey={curlWithoutApiKey} />
                      <div className="flex items-center">
                        <Button secondary className="mr-3" onClick={back} disabled={location.key === 'default'}>
                          {t('general.action.cancel')}
                        </Button>
                        <Button
                          type="submit"
                          loading={isLoading}
                          disabled={isLoading || !canRender || !isEmpty(formErrors)}
                        >
                          {t('general.action.render')}
                        </Button>
                      </div>
                    </div>
                  </form>
                )}
                {showScrollToTop && (
                  <div className="sticky z-50 h-0">
                    <a
                      href="#options"
                      className="absolute bottom-3 right-4 sm:right-6 md:-right-11 lg:-right-14"
                      title={t('general.action.scrollTop')}
                    >
                      <Button secondary icon={<ChevronDoubleUpIcon className="h-8 w-8" />} roundedFull />
                    </a>
                  </div>
                )}
                {showAdvancedOptions && (
                  <RenderAdvancedOptionsForm
                    onBack={() => setShowAdvancedOptions(false)}
                    advancedOptions={render}
                    onSave={(newRenderOptions: RenderOptionsDto) => {
                      setRender({
                        ...render,
                        outputFormat: newRenderOptions.outputFormat,
                        webhook: newRenderOptions.webhook,
                        options: newRenderOptions.options
                      });
                    }}
                    onValidation={handleInvalidUrls}
                    canSave={urlsValid}
                  />
                )}
              </>
            )}
            {!initialized && <EmptyTemplate />}
          </div>
        </div>
      </div>
    </div>
  );
});
