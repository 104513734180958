import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { Alert, Loading, WideSlideover } from '@src/components';
import { useGetTeamMembers } from '@src/hooks';
import { SubscriptionIdentifier } from '@src/models';
import { getPaddleSubscriptionId, useV2Packages } from '@src/models/subscription/utils';
import { State, useGlobalState } from '@src/state/store';

import {
  SubscriptionPackageExplorerV2,
  SubscriptionPackageProV1,
  SubscriptionPackageProV2,
  SubscriptionPackageStarterV1,
  SubscriptionPackageStarterV2,
  SubscriptionPackageTeamV1,
  SubscriptionPackageTeamV2
} from './SubscriptionPackage';

type SubscriptionPlanUpgradeProps = {
  visible: boolean;
  onClose: () => void;
};

export const SubscriptionPlanUpgrade = ({ visible, onClose }: SubscriptionPlanUpgradeProps) => {
  const { t } = useTranslation();
  const [user] = useGlobalState(State.USER);
  const [organization] = useGlobalState(State.ORGANIZATION);

  const { data: teamMembers, isLoading: isLoadingTeamMembers } = useGetTeamMembers(true, { silentNotifications: true });
  const disableStarterPackage = teamMembers && teamMembers.length > 1;
  const disableExplorerPackage = teamMembers && teamMembers.length > 1;

  const [upgradeStarted, setUpgradeStarted] = useState(false);
  const [showYearlyPlans, setShowYearlyPlans] = useState(true);

  // reset state and close
  const resetAndClose = () => {
    setUpgradeStarted(false);
    onClose();
  };

  // fires on the upgrade button click
  const onUpgrade = useCallback(
    (s: SubscriptionIdentifier) => {
      setUpgradeStarted(true);

      const passthrough = {
        userId: user?.id,
        organizationId: organization?.id
      };

      // @ts-expect-error Cannot find name 'Paddle'.
      Paddle.Checkout.open({
        method: 'inline',
        product: getPaddleSubscriptionId(s),
        email: user?.email,
        passthrough: JSON.stringify(passthrough),
        allowQuantity: false,
        disableLogout: true,
        frameTarget: 'checkout-container',
        frameInitialHeight: 450,
        // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
        frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;'
      });
    },
    [organization?.id, user?.id, user?.email]
  );

  return (
    <WideSlideover
      visible={visible}
      onClose={resetAndClose}
      title={t('components.user.SubscriptionPlanUpgrade.title')}
      subtitle={t('components.user.SubscriptionPlanUpgrade.subtitle')}
    >
      <>
        {visible && <div className="checkout-container" />}
        <div className="space-y-6 overflow-y-scroll px-4 py-5 sm:p-6">
          {isLoadingTeamMembers && <Loading />}
          {!isLoadingTeamMembers && !upgradeStarted && (
            <>
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    {t('general.action.selectATab')}
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    value={showYearlyPlans ? 'Y' : 'M'}
                    onChange={e => setShowYearlyPlans(e.target.value === 'Y')}
                  >
                    <option key={'M'}>{t('components.user.SubscriptionPlanUpgrade.monthlyPlans')}</option>
                    <option key={'Y'}>{t('components.user.SubscriptionPlanUpgrade.yearlyPlans')}</option>
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex" aria-label="Tabs">
                      <button
                        onClick={() => setShowYearlyPlans(false)}
                        className={classNames(
                          !showYearlyPlans
                            ? 'border-indigo-500 text-indigo-600'
                            : 'cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'w-1/2 border-b-2 px-1 py-4 text-center text-sm font-medium'
                        )}
                        aria-current={!showYearlyPlans ? 'page' : undefined}
                      >
                        {t('components.user.SubscriptionPlanUpgrade.monthlyPlans')}
                      </button>
                      <button
                        onClick={() => setShowYearlyPlans(true)}
                        className={classNames(
                          showYearlyPlans
                            ? 'border-indigo-500 text-indigo-600'
                            : 'cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'w-1/2 border-b-2 px-1 py-4 text-center text-sm font-medium'
                        )}
                        aria-current={showYearlyPlans ? 'page' : undefined}
                      >
                        <span>{t('components.user.SubscriptionPlanUpgrade.yearlyPlans')}</span>
                        <span className="ml-1 inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
                          {t('components.user.SubscriptionPlanUpgrade.yearlyDiscount')}
                        </span>
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
              {!showYearlyPlans && (
                <>
                  {!disableStarterPackage ? (
                    <>
                      {!useV2Packages && <SubscriptionPackageStarterV1 onUpgrade={onUpgrade} />}
                      {useV2Packages && <SubscriptionPackageStarterV2 onUpgrade={onUpgrade} />}
                    </>
                  ) : (
                    <Alert
                      type={'info'}
                      show
                      alertContent={
                        <p>
                          <Trans i18nKey={'components.user.SubscriptionPlanUpgrade.starterPlanNotAvailable'}>
                            <span className="font-semibold">Starter plan</span> not available because your team has
                            members.
                          </Trans>
                        </p>
                      }
                    />
                  )}
                  {!useV2Packages && (
                    <>
                      <SubscriptionPackageTeamV1 onUpgrade={onUpgrade} />
                      <SubscriptionPackageProV1 onUpgrade={onUpgrade} />
                    </>
                  )}
                  {useV2Packages && (
                    <>
                      {!disableExplorerPackage ? (
                        <SubscriptionPackageExplorerV2 onUpgrade={onUpgrade} />
                      ) : (
                        <Alert
                          type={'info'}
                          show
                          alertContent={
                            <p>
                              <Trans i18nKey={'components.user.SubscriptionPlanUpgrade.explorerPlanNotAvailable'}>
                                <span className="font-semibold">Explorer plan</span> not available because your team has
                                members.
                              </Trans>
                            </p>
                          }
                        />
                      )}
                      <SubscriptionPackageTeamV2 onUpgrade={onUpgrade} />
                      <SubscriptionPackageProV2 onUpgrade={onUpgrade} />
                    </>
                  )}
                </>
              )}
              {showYearlyPlans && (
                <>
                  {!disableStarterPackage ? (
                    <>
                      {!useV2Packages && <SubscriptionPackageStarterV1 yearly onUpgrade={onUpgrade} />}
                      {useV2Packages && <SubscriptionPackageStarterV2 yearly onUpgrade={onUpgrade} />}
                    </>
                  ) : (
                    <Alert
                      type={'info'}
                      show
                      alertContent={
                        <p>
                          <Trans i18nKey={'components.user.SubscriptionPlanUpgrade.starterPlanNotAvailable'}>
                            <span className="font-semibold">Starter plan</span> not available because your team has
                            members.
                          </Trans>
                        </p>
                      }
                    />
                  )}
                  {!useV2Packages && (
                    <>
                      <SubscriptionPackageTeamV1 yearly onUpgrade={onUpgrade} />
                      <SubscriptionPackageProV1 yearly onUpgrade={onUpgrade} />
                    </>
                  )}
                  {useV2Packages && (
                    <>
                      {!disableExplorerPackage ? (
                        <SubscriptionPackageExplorerV2 yearly onUpgrade={onUpgrade} />
                      ) : (
                        <Alert
                          type={'info'}
                          show
                          alertContent={
                            <p>
                              <Trans i18nKey={'components.user.SubscriptionPlanUpgrade.explorerPlanNotAvailable'}>
                                <span className="font-semibold">Explorer plan</span> not available because your team has
                                members.
                              </Trans>
                            </p>
                          }
                        />
                      )}
                      <SubscriptionPackageTeamV2 yearly onUpgrade={onUpgrade} />
                      <SubscriptionPackageProV2 yearly onUpgrade={onUpgrade} />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </>
    </WideSlideover>
  );
};
