import { decode, encode } from 'js-base64';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Button, PlainlyCombobox } from '@src/components/common';
import { useGetBatchRenderList, useQueryParams } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { BatchRenderDto } from '@src/models';
import { RENDER_BATCH_DOWNLOAD } from '@src/routes';

export const RenderBatchDownloadOptions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { searchQuery, withQueryParams } = useQueryParams();
  const encodedBatchRender = searchQuery.get('batchRender');
  const decodedBatchRender: BatchRenderDto | undefined = encodedBatchRender
    ? JSON.parse(decode(encodedBatchRender))
    : undefined;

  const { step } = useParams() as { step: string };
  const stepNum = parseInt(step) || 1;

  const { isLoading, data, isRefetching } = useGetBatchRenderList();

  const loading = isLoading || isRefetching;

  return (
    <div className="space-y-6">
      <div className="bg-white px-4 py-5 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t('components.render.batchDownload.RenderBatchDownloadOptions.title')}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {t('components.render.batchDownload.RenderBatchDownloadOptions.description')}
            </p>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <label htmlFor="batchRender" className="block text-sm font-medium text-gray-700">
              {t('components.render.common.batchDownload')}
            </label>
            <p className="text-sm text-gray-500">
              {t('components.render.batchDownload.RenderBatchDownloadOptions.batchRenderIdDesc')}
            </p>
            <PlainlyCombobox
              id="batchRender"
              hasSearch
              className="mt-1"
              data={data?.map((batchRender: BatchRenderDto) => ({
                badge: batchRender.total.toString(),
                value: batchRender.batchRenderId,
                customLabel: (
                  <div className="flex flex-row gap-1">
                    <span>{batchRender.batchRenderId.split('|')[1]}</span>
                    <span className="text-sm text-gray-500 group-hover:text-gray-100">
                      ({localizationHelper.forDate().formatDateTimeStringLocally(batchRender.createdDate)})
                    </span>
                  </div>
                ),
                label: `${
                  batchRender.batchRenderId.split('|')[1]
                } (${localizationHelper.forDate().formatDateTimeStringLocally(batchRender.createdDate)})`,
                item: batchRender,
                selected: batchRender.batchRenderId === decodedBatchRender?.batchRenderId
              }))}
              onSelectionChange={item => {
                item
                  ? navigate(
                      withQueryParams(generatePath(RENDER_BATCH_DOWNLOAD, { step: stepNum.toString() }), {
                        batchRender: encode(JSON.stringify(item))
                      })
                    )
                  : navigate(generatePath(RENDER_BATCH_DOWNLOAD, { step: stepNum.toString() }));
              }}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          disabled={!decodedBatchRender}
          onClick={() =>
            navigate(
              withQueryParams(generatePath(RENDER_BATCH_DOWNLOAD, { step: '2' }), {
                batchRender: encodedBatchRender
              })
            )
          }
        >
          {t('general.action.next')}
        </Button>
      </div>
    </div>
  );
};
