import { Fragment, ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Menu, MenuItem, MenuItems, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  DocumentArrowUpIcon,
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  VideoCameraIcon
} from '@heroicons/react/24/outline';

import { Button } from '../button';

import { NotificationLinks } from './helpNotificationLinks';

type HelpNotificationProps = {
  type: 'button' | 'badge';
  links: NotificationLinks;
  className?: string;
  additionalItems?: { label: string; link: string; icon: ReactElement }[];
};

export const HelpNotification = ({ type, links, className, additionalItems }: HelpNotificationProps) => {
  const { t } = useTranslation();

  const { apiReference, documentation, videoTutorial } = links;

  const getMenuButton = () => {
    switch (type) {
      case 'button':
        return (
          <Button menu help icon={<QuestionMarkCircleIcon />} rightIcon={<ChevronDownIcon />} className={className}>
            {t('components.HelpNotification.help')}
          </Button>
        );
      case 'badge':
        return (
          <Button menu small help className={className}>
            {t('components.HelpNotification.help')}
          </Button>
        );
    }
  };
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>{getMenuButton()}</div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          anchor="bottom end"
          className="z-50 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="py-1">
            {documentation && (
              <MenuItem>
                {({ focus }) => (
                  <a
                    href={documentation}
                    target="_blank"
                    rel="noreferrer"
                    className={classNames(
                      focus ? 'bg-indigo-100 text-indigo-600' : 'text-gray-700',
                      'flex px-4 py-2 text-sm'
                    )}
                  >
                    <DocumentTextIcon className="mr-2 h-5 w-5" />
                    {t('components.HelpNotification.documentation')}
                  </a>
                )}
              </MenuItem>
            )}
            {videoTutorial && (
              <MenuItem>
                {({ focus }) => (
                  <a
                    href={videoTutorial}
                    target="_blank"
                    rel="noreferrer"
                    className={classNames(
                      focus ? 'bg-indigo-100 text-indigo-600' : 'text-gray-700',
                      'flex px-4 py-2 text-sm'
                    )}
                  >
                    <VideoCameraIcon className="mr-2 h-5 w-5" />
                    {t('components.HelpNotification.videoTutorial')}
                  </a>
                )}
              </MenuItem>
            )}
            {apiReference && (
              <MenuItem>
                {({ focus }) => (
                  <a
                    href={apiReference}
                    target="_blank"
                    rel="noreferrer"
                    className={classNames(
                      focus ? 'bg-indigo-100 text-indigo-600' : 'text-gray-700',
                      'flex px-4 py-2 text-sm'
                    )}
                  >
                    <DocumentArrowUpIcon className="mr-2 h-5 w-5" />
                    {t('components.common.apiReference')}
                  </a>
                )}
              </MenuItem>
            )}
            {additionalItems?.map((item, index) => (
              <MenuItem key={index}>
                {({ focus }) => (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    className={classNames(
                      focus ? 'bg-indigo-100 text-indigo-600' : 'text-gray-700',
                      'flex px-4 py-2 text-sm'
                    )}
                  >
                    {item.icon}
                    {item.label}
                  </a>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};
