import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';

import { API_REFETCH_INTERVAL } from '@src/constants';
import { useAxiosModify, useAxiosRead } from '@src/hooks';
import { ErrorOptions, Project, ProjectMetaData, ProjectShareDto, StrictFontChecks } from '@src/models';
import { PartialBy } from '@src/types';

const PROJECTS_CACHE_ROOT = 'projects';

// refresh project list and search as long as one in analysis, each 15s
const getProjectListRefreshInterval = (projects?: Project[]): number | false => {
  if (projects) {
    const anyNotDone = projects.some(project => !(project.analysis?.done || project.analysis?.failed));
    if (anyNotDone) {
      return API_REFETCH_INTERVAL;
    }
  }
  return false;
};

// Cache utils

export const projectsCacheAdd = (client: QueryClient, project: Project) => {
  // Add new project to the list
  client.setQueryData<Project[]>(PROJECTS_CACHE_ROOT, projects => (projects ? [...projects, project] : [project]));
  // Add new project details
  client.setQueryData([PROJECTS_CACHE_ROOT, project.id], project);
};

export const projectsCacheRemove = (client: QueryClient, projectId: string) => {
  // Remove project from the list
  client.setQueryData<Project[]>(PROJECTS_CACHE_ROOT, projects => projects?.filter(p => p.id !== projectId) || []);
  // Remove cache
  client.removeQueries([PROJECTS_CACHE_ROOT, projectId]);
};

export const projectCacheReplace = (client: QueryClient, project: Project, structureChanged: boolean = false) => {
  // Update the list
  client.setQueryData<Project[]>(PROJECTS_CACHE_ROOT, projects =>
    projects ? projects.map(p => (p.id === project.id ? project : p)) : [project]
  );
  // Update the details
  client.setQueryData([PROJECTS_CACHE_ROOT, project.id], project);

  // Invalidate metadata and files queries only if structure changed
  if (structureChanged) {
    client.invalidateQueries([PROJECTS_CACHE_ROOT, project.id, 'metadata']);
    client.invalidateQueries([PROJECTS_CACHE_ROOT, project.id, 'files']);
  }
};

export const useGetProjects = () => {
  const { get } = useAxiosRead<Project[]>();

  const { data, isLoading, error } = useQuery<Project[]>(
    PROJECTS_CACHE_ROOT,
    async () => {
      const response = await get('/projects');
      return response.data;
    },
    {
      refetchInterval: getProjectListRefreshInterval
    }
  );

  return { data, isLoading, error };
};

export const useGetProjectDetails = (projectId: string | undefined, enabled = true, errorOptions?: ErrorOptions) => {
  const { get } = useAxiosRead<Project>({}, errorOptions);

  const cacheKey = [PROJECTS_CACHE_ROOT, projectId];

  const { data, isLoading, error, refetch } = useQuery<Project>(
    cacheKey,
    async () => {
      const response = await get(`/projects/${projectId}`);
      return response.data;
    },
    {
      enabled: !!projectId && enabled,
      refetchInterval: (project?: Project) =>
        project && !(project?.analysis?.done || project?.analysis?.failed) ? API_REFETCH_INTERVAL : false
    }
  );

  return { data, isLoading, error, refetch };
};

export const useGetProjectMetadata = (
  projectId: string,
  enabled: boolean,
  refetchOnMount?: boolean,
  errorOptions?: ErrorOptions
) => {
  const { get } = useAxiosRead<ProjectMetaData>({}, errorOptions);

  const cacheKey = [PROJECTS_CACHE_ROOT, projectId, 'metadata'];

  const { data, isLoading, error, isRefetching } = useQuery<ProjectMetaData>(
    cacheKey,
    async () => {
      const response = await get(`/projects/${projectId}/meta`);
      return response.data;
    },
    {
      enabled,
      refetchOnMount
    }
  );

  return { data, isLoading, error, isRefetching };
};

export const useGetProjectFile = (projectId: string, filePath: string, enabled: boolean) => {
  const { get } = useAxiosRead<Blob>({}, { silentNotifications: true });

  const cacheKey = [PROJECTS_CACHE_ROOT, projectId, 'files', filePath];

  const { data, isLoading, error, refetch } = useQuery<Blob>(
    cacheKey,
    async () => {
      const decodedFilePath = decodeURIComponent(filePath);

      const response = await get(`/projects/${projectId}/storage`, {
        params: { file: decodedFilePath },
        responseType: 'blob'
      });

      return response.data;
    },
    { enabled }
  );

  return { data, isLoading, error, refetch };
};

type ProjectUploadParams = {
  name?: string;
  description?: string;
  tags?: string[];
  file: File;
  strictFontChecks?: StrictFontChecks | null;
};

export const useUploadProject = () => {
  const { post } = useAxiosModify<FormData, Project>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({ name, description, tags, file }: ProjectUploadParams) => {
      const data = new FormData();
      data.append('file', file);
      name && data.append('name', name);
      description && data.append('description', description);
      tags?.forEach(tag => data.append('tags', tag));

      const response = await post('/projects', data);
      return response.data;
    },
    onSuccess: project => {
      projectsCacheAdd(queryClient, project);
    }
  });

  return { mutateAsync, isLoading, error };
};

type ProjectEditParams = {
  projectId: string;
  project: PartialBy<ProjectUploadParams, 'file'>;
};

export const useEditProject = () => {
  const { post } = useAxiosModify<FormData, Project>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({ projectId, project }: ProjectEditParams) => {
      const { name, description, tags, file } = project;

      const data = new FormData();
      file && data.append('file', file);
      name && data.append('name', name);
      description !== undefined && description !== null && data.append('description', description);
      tags?.forEach(tag => data.append('tags', tag));
      data.append('strictFontChecks', project.strictFontChecks || StrictFontChecks.DEFAULT);

      const response = await post(`/projects/${projectId}`, data);

      return response.data;
    },
    onSuccess: (edited: Project, vars) => {
      projectCacheReplace(queryClient, edited, vars.project.file !== undefined);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useDeleteProject = () => {
  const { delete: deleteProject } = useAxiosModify<void, void>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (projectId: string) => {
      const response = await deleteProject(`/projects/${projectId}`);

      return response.data;
    },
    onSuccess: (_, projectId) => {
      projectsCacheRemove(queryClient, projectId);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useReanalyzeProject = () => {
  const { post } = useAxiosModify<void, Project>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (projectId: string) => {
      const response = await post(`/projects/${projectId}/reanalyze`);

      return response.data;
    },
    onSuccess: project => {
      projectCacheReplace(queryClient, project);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useUpgradeProject = () => {
  const { post } = useAxiosModify<void, Project>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (projectId: string) => {
      const response = await post(`/projects/${projectId}/upgrade`);

      return response.data;
    },
    onSuccess: project => {
      projectCacheReplace(queryClient, project);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useUpgradeAllProjects = () => {
  const { post } = useAxiosModify<void, void>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async () => {
      await post(`/projects/upgrade-all`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(PROJECTS_CACHE_ROOT);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useSetProjectSharing = () => {
  const { post } = useAxiosModify<ProjectShareDto, Project>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({ projectId, shareInfo }: { projectId: string; shareInfo: ProjectShareDto }) => {
      const response = await post(`/projects/${projectId}/share`, shareInfo);

      return response.data;
    },
    onSuccess: project => {
      projectCacheReplace(queryClient, project);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useDownloadProjectZip = (projectId: string) => {
  const { get } = useAxiosRead<Blob>({});

  const { data, isLoading, error, refetch } = useQuery<Blob>(
    [PROJECTS_CACHE_ROOT, projectId, 'download'],
    async () => {
      const response = await get(`/projects/${projectId}/download`, {
        responseType: 'blob'
      });

      return response.data;
    },
    { enabled: false }
  );

  return { data, isLoading, error, refetch };
};
