import { useTranslation } from 'react-i18next';

import { isEmpty } from '@src/utils';

import { DesignInputErrors } from './DesignInputErrors';

export type DesignBooleanInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  onFieldUpdate: (value: boolean) => void;
  errors?: string[];
};

export const DesignBooleanInput = ({ onFieldUpdate, errors, checked, ...rest }: DesignBooleanInputProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mr-3 flex h-5 items-center">
        <input
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          checked={checked}
          onChange={e => onFieldUpdate(e.target.checked)}
          {...rest}
        />
        <p className="ml-3 text-sm text-gray-700">{t('general.common.enabled')}</p>
      </div>
      {!isEmpty(errors) && <DesignInputErrors errors={errors} />}
    </>
  );
};
