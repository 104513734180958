import { createContext, useEffect, useState } from 'react';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://status.plainlyvideos.com',
  responseType: 'json'
});

// https://hyperping.com/docs/status-page/status-page-json
type StatusResponse = {
  indicator: 'up' | 'incident' | 'outage' | 'maintenance';
};

type StatusContextProps = {
  fetched: boolean;
  status: StatusResponse['indicator'] | undefined;
};

export const StatusContext = createContext<StatusContextProps>({} as StatusContextProps);

export const StatusProvider = ({ children }: { children: React.ReactNode }) => {
  const [fetched, setFetched] = useState(false);
  const [status, setStatus] = useState<StatusResponse['indicator']>();

  useEffect(() => {
    if (fetched) {
      return;
    }

    axiosInstance
      .get<StatusResponse>('status.json')
      .then(response => {
        setStatus(response.data.indicator);
        setFetched(true);
      })
      .catch(error => {
        // ignore
        setFetched(true);
        console.log('Error loading status from status.plainlyvideos.com', error);
      });
  }, [fetched]);

  return <StatusContext.Provider value={{ fetched, status }}>{children}</StatusContext.Provider>;
};
