import { useTranslation } from 'react-i18next';

import { DynamicScriptSection } from '@src/components';
import { DynamicScriptsHelpLinks, HelpNotification } from '@src/components/common';
import { DynamicScript, SelectedDynamicScripts } from '@src/types';
import { isEmpty } from '@src/utils';

export const RenderBatchDynamicScriptsTable = ({
  dynamicScripts,
  selectedDynamicScripts,
  onChange,
  columns
}: {
  dynamicScripts?: DynamicScript[];
  selectedDynamicScripts: SelectedDynamicScripts;
  onChange: (col: SelectedDynamicScripts) => void;
  columns?: string[];
}) => {
  const { t } = useTranslation();

  if (isEmpty(dynamicScripts)) return null;

  return (
    <div className="mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
      <div className="grid min-w-full grid-cols-3 divide-y divide-gray-300">
        <div className="col-span-3 flex items-center justify-between bg-gray-50">
          <div className="w-2/3 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
            {t('components.render.dynamicScripts.DynamicScriptsForm.title')}
            <HelpNotification className="ml-2" type="badge" links={DynamicScriptsHelpLinks} />
          </div>
        </div>
        <div className="col-span-3 divide-y divide-gray-200 bg-white">
          {dynamicScripts.map(d => (
            <div key={d.parameterName} className="w-full bg-white">
              <DynamicScriptSection
                selectedDynamicScripts={selectedDynamicScripts}
                parameterName={d.parameterName}
                scriptType={d.scriptType}
                onChange={onChange}
                batchRender={true}
                columns={columns}
                isComposition={d.isComposition}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
