import { useTranslation } from 'react-i18next';

import { Badge, Button, Loading, StyledA } from '@src/components';
import localizationHelper from '@src/i18n';
import { Subscription, SubscriptionIdentifier, SubscriptionStatus } from '@src/models';
import {
  canCancelSubscription,
  canCreateSubscription,
  canDowngradeSubscription,
  canUpdateSubscriptionPayment,
  canUpgradeSubscription
} from '@src/models/subscription/utils';

type SubscriptionOverviewCardProps = {
  subscription: Subscription;
  showPlansUpgrade: (currentSubscription?: Subscription) => void;
  showCancelSubscription: (cancelUrl?: string) => void;
  showUpdateSubscription: (updateUrl?: string) => void;
  showExistingPlanUpgrade: (currentSubscription?: Subscription) => void;
  loading: boolean;
  readOnly: boolean;
};

const priceFormatter = localizationHelper.forNumber({
  style: 'decimal',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
});

export const SubscriptionOverviewCard = ({
  subscription,
  showPlansUpgrade,
  showCancelSubscription,
  showUpdateSubscription,
  showExistingPlanUpgrade,
  loading,
  readOnly
}: SubscriptionOverviewCardProps) => {
  const { t } = useTranslation();

  // actions
  const canDowngrade = !readOnly && canDowngradeSubscription(subscription);
  const canUpgrade = !readOnly && canUpgradeSubscription(subscription);
  const canSubscribe = !readOnly && canCreateSubscription(subscription);
  const canCancel = !readOnly && canCancelSubscription(subscription);
  const canUpdatePayment = !readOnly && canUpdateSubscriptionPayment(subscription);

  return (
    <>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <div className="flex">
                <h3 className="mr-1 text-lg font-medium leading-6 text-gray-900">
                  {t('components.user.SubscriptionOverviewCard.title')}
                </h3>
                {subscription.status && (
                  <Badge
                    label={subscription.status}
                    type={subscription.status === SubscriptionStatus.ACTIVE ? 'green' : 'beta'}
                  />
                )}
              </div>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {t('components.user.SubscriptionOverviewCard.subtitle')}
              </p>
            </div>
            <div className="ml-4 mt-2 shrink-0">
              {canSubscribe && (
                <>
                  <Button disabled={loading} onClick={() => showPlansUpgrade()}>
                    {t('components.user.SubscriptionOverviewCard.changePlan')}
                  </Button>
                </>
              )}
              {canCancel && (
                <>
                  <Button
                    secondary
                    className="ml-2"
                    disabled={loading}
                    onClick={() => showCancelSubscription(subscription.cancelUrl)}
                  >
                    {t('components.user.common.cancelSubscription')}
                  </Button>
                </>
              )}
              {canUpgrade && (
                <>
                  <Button disabled={loading} className="ml-2" onClick={() => showExistingPlanUpgrade()}>
                    {t('components.user.SubscriptionOverviewCard.changePlan')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="border-b border-t border-gray-200 px-4 py-5 sm:px-6">
          {loading && <Loading />}
          {!loading && subscription && (
            <>
              {subscription.subscriptionIdentifier === SubscriptionIdentifier.FREE && (
                <>
                  <div className="text-sm text-gray-900">
                    <p> {t('components.user.SubscriptionOverviewCard.noSubscription')}</p>
                  </div>
                </>
              )}
              {subscription.subscriptionIdentifier !== SubscriptionIdentifier.FREE && (
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  {subscription.subscriptionIdentifier && (
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        {t('components.user.SubscriptionOverviewCard.plan')}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {t('components.user.SubscriptionOverviewCard.planIdentifier', {
                          context: subscription.subscriptionIdentifier
                        })}
                      </dd>
                    </div>
                  )}
                  {subscription.currency && subscription.billingInterval && subscription.price > 0 && (
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        {t('components.user.SubscriptionOverviewCard.price')}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {t('components.user.SubscriptionOverviewCard.priceDetails', {
                          price: priceFormatter.format(subscription.price),
                          currency: subscription.currency,
                          context: subscription.billingInterval
                        })}
                      </dd>
                    </div>
                  )}
                  {subscription.startedDate && (
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        {t('components.user.SubscriptionOverviewCard.startDate')}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {localizationHelper.forDate().formatDateTimeStringLocally(subscription.startedDate)}
                      </dd>
                    </div>
                  )}
                  {subscription.endDate && (
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        {t('components.user.SubscriptionOverviewCard.endDate')}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {localizationHelper.forDate().formatDateTimeStringLocally(subscription.endDate)}
                      </dd>
                    </div>
                  )}
                  {subscription.nextPaymentAmount !== undefined &&
                    subscription.nextPaymentCurrency &&
                    subscription.nextPaymentDate && (
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {t('components.user.SubscriptionOverviewCard.nextPayment')}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {t('components.user.SubscriptionOverviewCard.nextPaymentDescription', {
                            price: priceFormatter.format(subscription.nextPaymentAmount),
                            currency: subscription.nextPaymentCurrency,
                            date: localizationHelper.forDate().formatDateStringLocally(subscription.nextPaymentDate)
                          })}
                        </dd>
                      </div>
                    )}
                  {subscription.paymentMethod && (
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        {t('components.user.SubscriptionOverviewCard.paymentMethod')}
                      </dt>
                      <dd className="mt-1 flex flex-col text-sm text-gray-900">
                        <span>{subscription.paymentMethod}</span>
                        {canUpdatePayment && (
                          <span className="text-xs">
                            <StyledA
                              onClick={() => showUpdateSubscription(subscription.updateUrl)}
                              className="cursor-pointer "
                            >
                              {t('general.action.update')}
                            </StyledA>
                          </span>
                        )}
                      </dd>
                    </div>
                  )}
                </dl>
              )}
            </>
          )}
        </div>
        {canDowngrade && (
          <div className="px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2">
                <p className="text-sm italic text-gray-500">
                  {t('components.user.SubscriptionOverviewCard.changePlanInfo')}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
