import { Trans } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { SignalIcon as InProgressIcon } from '@heroicons/react/24/outline';
import {
  CheckIcon as SuccessIcon,
  CloudArrowUpIcon as PendingIcon,
  ExclamationTriangleIcon as WarningIcon,
  StopCircleIcon as CancelledIcon,
  XMarkIcon as ErrorIcon
} from '@heroicons/react/24/solid';
import localizationHelper from '@src/i18n';
import { Render, RenderState } from '@src/models';
import * as routes from '@src/routes';
import { PropsWithModel } from '@src/types';

export type RendersTimelineItemProps = PropsWithModel<Render> & {
  isLastItem: boolean;
};

const RendersTimelineItemIcon = ({ state }: { state?: RenderState }) => {
  switch (state) {
    case RenderState.IN_PROGRESS:
      return (
        <span className="flex h-8 w-8 animate-spin items-center justify-center rounded-full bg-indigo-300 ring-8 ring-white">
          <InProgressIcon className="h-5 w-5 text-white" />
        </span>
      );
    case RenderState.DONE:
      return (
        <span className="flex h-8 w-8 items-center justify-center rounded-full bg-green-400 ring-8 ring-white">
          <SuccessIcon className="h-5 w-5 text-white" />
        </span>
      );
    case RenderState.FAILED:
      return (
        <span className="flex h-8 w-8 items-center justify-center rounded-full bg-red-400 ring-8 ring-white">
          <ErrorIcon className="h-5 w-5 text-white" />
        </span>
      );
    case RenderState.INVALID:
      return (
        <span className="flex h-8 w-8 items-center justify-center rounded-full bg-yellow-400 ring-8 ring-white">
          <WarningIcon className="h-5 w-5 text-white" />
        </span>
      );
    case RenderState.CANCELLED:
      return (
        <span className="flex h-8 w-8 items-center justify-center rounded-full bg-stone-300 ring-8 ring-white">
          <CancelledIcon className="h-5 w-5 text-white" />
        </span>
      );
    case RenderState.PENDING:
    case RenderState.THROTTLED:
    case RenderState.QUEUED:
    default:
      return (
        <span className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-300 ring-8 ring-white">
          <PendingIcon className="h-5 w-5 text-white" />
        </span>
      );
  }
};

const getRenderMessageKey = ({ state }: Render) => {
  switch (state) {
    case RenderState.QUEUED:
      return 'components.render.RendersTimeline.renderQueued';
    case RenderState.IN_PROGRESS:
      return 'components.render.RendersTimeline.renderInProgress';
    case RenderState.DONE:
      return 'components.render.RendersTimeline.renderDone';
    case RenderState.FAILED:
      return 'components.render.RendersTimeline.renderFailed';
    case RenderState.INVALID:
      return 'components.render.RendersTimeline.renderInvalid';
    case RenderState.CANCELLED:
      return 'components.render.RendersTimeline.renderCancelled';
    case RenderState.THROTTLED:
      return 'components.render.RendersTimeline.renderThrottled';
    case RenderState.PENDING:
    default:
      return 'components.render.RendersTimeline.renderPending';
  }
};

export const RendersTimelineItem = ({ model: render, isLastItem }: RendersTimelineItemProps) => {
  return (
    <li>
      <div className="relative pb-8">
        {!isLastItem && <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />}
        <div className="relative flex space-x-3">
          <div>
            <RendersTimelineItemIcon state={render.state} />
          </div>
          <Link
            className="w-full cursor-pointer rounded-md p-1 hover:bg-gray-50"
            to={generatePath(routes.RENDER_DETAILS, { id: render.id })}
          >
            <div className="flex min-w-0 flex-1 justify-between space-x-4">
              <div>
                <p className="text-sm text-gray-500">
                  <Trans i18nKey={getRenderMessageKey(render)} tOptions={{ templateName: render.templateName }}>
                    Template <span className="break-all font-medium text-gray-900">name</span> state.
                  </Trans>
                </p>
              </div>
              <div className="m-auto text-right text-xs">
                <time>{localizationHelper.forDate().formatDistanceToNowWithDateString(render.createdDate, true)}</time>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </li>
  );
};
