/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const location = useLocation();

  return {
    searchQuery: useMemo(() => new URLSearchParams(location.search), [location.search]),
    withQueryParams: useCallback((url: string, params: { [key: string]: any }, removeUndefined = true): string => {
      if (removeUndefined) {
        Object.entries(params).forEach(([k, v]) => {
          if (!v) delete params[k];
        });
      }
      const queryString = new URLSearchParams(params).toString();
      return queryString ? `${url}?${queryString}` : url;
    }, []),
    updateQueryParams: useCallback((location: Location, params: { [key: string]: any }): string => {
      const currentParams = new URLSearchParams(location.search);
      Object.entries(params).forEach(([k, v]) => {
        if (v) {
          currentParams.set(k, v);
        } else {
          currentParams.delete(k);
        }
      });

      return currentParams.toString();
    }, [])
  };
};
