import { useEffect } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { PlainlyCombobox, RequiredMarker } from '@src/components';
import { AnyVoiceoverProviderSettings, VoiceoverProviderType } from '@src/models';
import { LANGUAGES } from '@src/tools/publishers/utils';

export const VoiceoverFormInputs = ({
  inputs,
  setInputs,
  disabled,
  selectedLang,
  onLanguageChange
}: {
  inputs: AnyVoiceoverProviderSettings;
  setInputs: (inputs: AnyVoiceoverProviderSettings) => void;
  disabled?: boolean;
  selectedLang?: string;
  onLanguageChange?: (valid: boolean) => void;
}) => {
  const { t } = useTranslation();

  const langSupported = !selectedLang
    ? true
    : LANGUAGES.find(l => l.code === selectedLang)?.supportedBy.includes(inputs.type);
  const allSupportedLangs =
    selectedLang &&
    LANGUAGES.filter(
      lang => lang.code.startsWith(selectedLang.split('-')[0]) && lang.supportedBy.includes(inputs.type)
    );

  useEffect(() => {
    onLanguageChange?.(langSupported === false);
  }, [langSupported, onLanguageChange]);

  return (
    <>
      <div>
        <label htmlFor="type" className="block text-sm font-medium text-gray-700">
          {t('components.publishers.common.provider')}
          <RequiredMarker />
          <p className="text-sm font-normal text-gray-500">
            {t('components.publishers.forms.voiceover.typeVoiceoverDescription')}
          </p>
        </label>
        <PlainlyCombobox
          id="type"
          disabled={disabled}
          className="mt-1"
          data={Object.values(VoiceoverProviderType).map(type => ({
            name: type,
            item: type,
            label: t('components.publishers.forms.voiceover.type', { context: type }),
            selected: type === inputs?.type
          }))}
          clearDisabled
          onSelectionChange={item =>
            setInputs({
              ...inputs,
              type: item as VoiceoverProviderType
            })
          }
        />
        {langSupported === false && (
          <>
            <div
              className={classNames(
                'mt-1 flex w-full justify-start text-red-900 md:mt-2',
                allSupportedLangs ? 'items-start' : 'items-center'
              )}
            >
              <ExclamationTriangleIcon className="mr-1.5 h-5 w-5 shrink-0 text-red-400" />
              <p className="flex flex-col justify-center text-xs text-red-900">
                {allSupportedLangs
                  ? t('components.publishers.forms.voiceover.languageNotSupportedLangs', {
                      langs: allSupportedLangs.map(lang => lang.name).join(', ')
                    })
                  : t('components.publishers.forms.voiceover.languageNotSupported')}
              </p>
            </div>
          </>
        )}
      </div>
      {langSupported !== false && (
        <>
          <div>
            <label htmlFor="audioConfigPitch" className="block text-sm font-medium text-gray-700">
              {t('components.publishers.forms.voiceover.audioConfigPitch')}
              <p className="text-sm font-normal text-gray-500">
                <Trans
                  i18nKey={'components.publishers.forms.voiceover.audioConfigPitchDescription'}
                  tOptions={{
                    phrase: 'Audio Config'
                  }}
                >
                  Optional speaking pitch, in the range [-20.0, 20.0]. 20 means increase 20 semitones from the original
                  pitch. -20 means decrease 20 semitones from the original pitch. See
                  <a
                    className="text-indigo-600 hover:text-indigo-500"
                    href="https://cloud.google.com/text-to-speech/docs/reference/rest/v1/AudioConfig"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Audio Config
                  </a>
                  for details.
                </Trans>
              </p>
            </label>
            <input
              disabled={disabled}
              min={-20}
              max={20}
              id="audioConfigPitch"
              name="audioConfigPitch"
              type="number"
              inputMode="decimal"
              step="any"
              className="mt-1 block w-full rounded-md border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50"
              onChange={e =>
                setInputs({
                  ...inputs,
                  audioConfigPitch: e.target.value ? parseFloat(e.target.value) : undefined
                })
              }
              value={inputs.audioConfigPitch ? inputs.audioConfigPitch : ''}
            />
          </div>
          <div>
            <label htmlFor="audioConfigSpeakingRate" className="block text-sm font-medium text-gray-700">
              {t('components.publishers.forms.voiceover.audioConfigSpeakingRate')}
              <p className="text-sm font-normal text-gray-500">
                <Trans
                  i18nKey={'components.publishers.forms.voiceover.audioConfigSpeakingRateDescription'}
                  tOptions={{
                    phrase: 'Audio Config'
                  }}
                >
                  Optional, speaking rate/speed, in the range [0.25, 4.0]. 1.0 is the normal native speed supported by
                  the specific voice. 2.0 is twice as fast, and 0.5 is half as fast. If unset, defaults to the native
                  1.0 speed. See
                  <a
                    className="text-indigo-600 hover:text-indigo-500"
                    href="https://cloud.google.com/text-to-speech/docs/reference/rest/v1/AudioConfig"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Audio Config
                  </a>
                  for details.
                </Trans>
              </p>
            </label>
            <input
              disabled={disabled}
              min={0.25}
              max={4}
              id="audioConfigSpeakingRate"
              name="audioConfigSpeakingRate"
              type="number"
              inputMode="decimal"
              step="any"
              className="mt-1 block w-full rounded-md border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50"
              onChange={e =>
                setInputs({
                  ...inputs,
                  audioConfigSpeakingRate: e.target.value ? parseFloat(e.target.value) : undefined
                })
              }
              value={inputs.audioConfigSpeakingRate ? inputs.audioConfigSpeakingRate : ''}
            />
          </div>
          <div>
            <label htmlFor="voiceName" className="block text-sm font-medium text-gray-700">
              {t('components.publishers.forms.voiceover.voiceName')}
              <p className="text-sm font-normal text-gray-500">
                <Trans
                  i18nKey={'components.publishers.forms.voiceover.voiceNameDescription'}
                  tOptions={{
                    phrase: 'available voices'
                  }}
                >
                  The name of the voice. If not set, the service will choose a voice based on the other parameters such
                  as video target language and gender. See
                  <a
                    className="text-indigo-600 hover:text-indigo-500"
                    href="https://cloud.google.com/text-to-speech/docs/voices"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    available voices
                  </a>
                  for details.
                </Trans>
              </p>
            </label>
            <input
              disabled={disabled}
              id="voiceName"
              name="voiceName"
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50"
              onChange={e =>
                setInputs({
                  ...inputs,
                  voiceName: e.target.value
                })
              }
              value={inputs.voiceName ? inputs.voiceName : ''}
            />
            <div className="mt-2 rounded-md border border-yellow-100 bg-yellow-50 p-1.5">
              <p className="flex items-center justify-center text-xs text-yellow-900">
                <ExclamationTriangleIcon className="mr-1.5 h-5 w-5 shrink-0 text-yellow-400" />
                {t('components.article.ArticleVideo.voiceoverSettings.voiceoverWarn')}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};
