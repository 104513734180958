import { useCallback, useEffect, useState } from 'react';
import makeAsyncScriptLoader from 'react-async-script';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Alert,
  Loading,
  OrganizationSettingsHeader,
  OrganizationSettingsNavigation,
  ScrollingPage
} from '@src/components';
import { SubscriptionOverviewCard, SubscriptionPlanUpgrade, SubscriptionResourcesCard } from '@src/components/user';
import { PaddleOverrideSlideover } from '@src/components/user/PaddleOverrideSlideover';
import { PaymentsListCard } from '@src/components/user/PaymentsListCard';
import { SubscriptionExistingPlanUpgrade } from '@src/components/user/SubscriptionExistingPlanUpgrade';
import { useGetSubscription, useQueryParams } from '@src/hooks';
import { OrganizationRole, Subscription } from '@src/models';
import {
  canCreateSubscription,
  canUpgradeSubscription,
  isActiveSubscription,
  isCustomPlan,
  isExternalSubscription
} from '@src/models/subscription/utils';
import { State, useGlobalState } from '@src/state/store';

type PlansUpgrade = {
  visible: boolean;
  subscription?: Subscription;
};

type CancelOrUpdateSubscription = {
  visible: boolean;
  url?: string;
};

const setupPaddle = () => {
  if (import.meta.env.VITE_APP_PADDLE_SANDBOX === 'true') {
    // @ts-expect-error Cannot find name 'Paddle'.
    Paddle.Environment.set('sandbox');
  }
  if (import.meta.env.VITE_APP_PADDLE_VENDOR_ID) {
    // @ts-expect-error Cannot find name 'Paddle'.
    Paddle.Setup({ vendor: parseInt(import.meta.env.VITE_APP_PADDLE_VENDOR_ID) });
  }
};

export const OrganizationSubscriptionPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { searchQuery, updateQueryParams } = useQueryParams();
  const openPlans = !!searchQuery.get('openPlans');
  const dismissOpenPlans = useCallback(() => {
    if (openPlans) {
      navigate({ search: `?${updateQueryParams(window.location, { openPlans: undefined })}` }, { replace: true });
    }
  }, [openPlans, navigate, updateQueryParams]);

  const [globalSubscription, setGlobalSubscription] = useGlobalState(State.SUBSCRIPTION);
  const { isLoading, data: updateData, refetch: getSubscription } = useGetSubscription();

  const [plansUpgrade, setPlansUpgrade] = useState<PlansUpgrade>({ visible: false });
  const togglePlansUpgradeVisible = () => {
    const visible = !plansUpgrade.visible;
    setPlansUpgrade({ ...plansUpgrade, visible });
    if (!visible) {
      getSubscription().then(response => {
        // refresh if I can still subscribe
        if (!response.data || canCreateSubscription(response.data)) {
          setTimeout(getSubscription, 3000);
        }
      });
      dismissOpenPlans();
    }
  };

  const [existingPlansUpgrade, setExistingPlansUpgrade] = useState<PlansUpgrade>({ visible: false });
  const toggleExistingPlansUpgradeVisible = () => {
    const visible = !existingPlansUpgrade.visible;
    setExistingPlansUpgrade({ ...existingPlansUpgrade, visible });
    if (!visible) {
      getSubscription();
      dismissOpenPlans();
    }
  };

  // ensure we refresh the subscription
  // if it's not existing globally
  useEffect(() => {
    if (!globalSubscription) {
      getSubscription();
    }
  }, [getSubscription, globalSubscription]);

  const [cancelSubscription, setCancelSubscription] = useState<CancelOrUpdateSubscription>({ visible: false });
  const toggleCancelSubscriptionVisible = () => {
    setCancelSubscription({ ...cancelSubscription, visible: !cancelSubscription.visible });
    getSubscription();
  };

  const [updateSubscription, setUpdateSubscription] = useState<CancelOrUpdateSubscription>({ visible: false });
  const toggleUpdateSubscriptionVisible = () => {
    setUpdateSubscription({ ...updateSubscription, visible: !updateSubscription.visible });
    getSubscription();
  };

  // is postpaid, works only for single org per user
  const [organization] = useGlobalState(State.ORGANIZATION);
  const isOrganizationOwner = organization?.role === OrganizationRole.OWNER;

  const customPlan = (globalSubscription && isCustomPlan(globalSubscription)) || false;
  const showInvoices = (globalSubscription && isExternalSubscription(globalSubscription)) || false;
  const canSubscribe = (globalSubscription && canCreateSubscription(globalSubscription)) || false;
  const canUpgrade = (globalSubscription && canUpgradeSubscription(globalSubscription)) || false;

  // script loader
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const PaddleScriptLoader = makeAsyncScriptLoader('https://cdn.paddle.com/paddle/paddle.js')(Loading);

  useEffect(() => {
    if (!openPlans) {
      return;
    }

    // if open plans is asked for, we should check if subscription or upgrade is possible
    if (canSubscribe || canUpgrade) {
      // note canSubscribe and canUpgrade are mutually exclusive
      setPlansUpgrade({ visible: canSubscribe });
      setExistingPlansUpgrade({ visible: canUpgrade });
    } else {
      setPlansUpgrade({ visible: false });
      setExistingPlansUpgrade({ visible: false });
    }
  }, [openPlans, canSubscribe, canUpgrade]);

  useEffect(() => {
    if (updateData) {
      setGlobalSubscription(updateData);
    }
  }, [updateData, setGlobalSubscription]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('general.pageTitle.UserSubscriptionPage')}</title>
      </Helmet>
      {!scriptLoaded && (
        <>
          <PaddleScriptLoader
            asyncScriptOnLoad={() => {
              setupPaddle();
              setScriptLoaded(true);
            }}
          />
        </>
      )}
      {scriptLoaded && isOrganizationOwner && (
        <>
          <SubscriptionPlanUpgrade visible={plansUpgrade.visible} onClose={togglePlansUpgradeVisible} />
          {globalSubscription && (
            <SubscriptionExistingPlanUpgrade
              visible={existingPlansUpgrade.visible}
              onClose={toggleExistingPlansUpgradeVisible}
              subscription={globalSubscription}
            />
          )}
          <PaddleOverrideSlideover
            visible={cancelSubscription.visible}
            url={cancelSubscription.url}
            onClose={toggleCancelSubscriptionVisible}
            title={t('components.user.common.cancelSubscription')}
            subtitle={t('components.user.PaddleOverrideSlideover.CancelSubscription.subtitle')}
          />
          <PaddleOverrideSlideover
            visible={updateSubscription.visible}
            url={updateSubscription.url}
            onClose={toggleUpdateSubscriptionVisible}
            title={t('components.user.PaddleOverrideSlideover.UpdateSubscription.title')}
            subtitle={t('components.user.PaddleOverrideSlideover.UpdateSubscription.subtitle')}
          />
        </>
      )}
      <ScrollingPage className="space-y-6">
        <OrganizationSettingsHeader />
        <OrganizationSettingsNavigation tab="subscription" />
        {customPlan && <Alert type="success" show={true} alertContent={t('components.paddle.customAlert')} />}
        {globalSubscription && (
          <>
            <SubscriptionOverviewCard
              subscription={globalSubscription}
              showPlansUpgrade={subscription => setPlansUpgrade({ subscription, visible: true })}
              showExistingPlanUpgrade={subscription => setExistingPlansUpgrade({ subscription, visible: true })}
              showCancelSubscription={url => {
                setCancelSubscription({ url, visible: true });
              }}
              showUpdateSubscription={url => {
                setUpdateSubscription({ url, visible: true });
              }}
              loading={isLoading}
              readOnly={!isOrganizationOwner}
            />
            <SubscriptionResourcesCard resources={globalSubscription.resources} />
            {showInvoices && isOrganizationOwner && <PaymentsListCard />}
          </>
        )}
      </ScrollingPage>
    </HelmetProvider>
  );
};
